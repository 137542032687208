import { Content } from "antd/es/layout/layout";
import { useMediaQuery } from "react-responsive";
import { WelcomeText } from "./stats";
import { DesktopRepositoryTable } from "./desktopRepositoryTable";
import { MobileRepositoryTable } from "./mobileRepositoryTable";

export function RepositoryTable() {
  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });
  if (!isDesktop) {
    return <>{MobileRepositoryTable()}</>;
  } else {
    return (
      <Content style={{ padding: "0 24px", minHeight: 280 }}>
        <WelcomeText />
        <br />
        <br />
        {DesktopRepositoryTable()}
      </Content>
    );
  }
}
