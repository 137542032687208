import { Tag } from "antd";
import { respository_stats } from "../../../types";

export const Stats = (stats: respository_stats) => {
  return (
    <>
      <div
        style={{
          padding: "24px",
          marginTop: "24px",
          backgroundColor: "#fff",
          borderRight: "1px solid #f0f0f0",
        }}
      >
        <h3>Database contents:</h3>
        <p>
          <strong>{stats.numOfRepresentatives}</strong> representatives
        </p>
        <p>
          <strong>{stats.numOfStructures}</strong> structures
        </p>
        <p>
          BGSU version&nbsp;
          <a
            target="_blank"
            rel="noreferrer"
            href={
              "http://rna.bgsu.edu/rna3dhub/nrlist/release/" +
              stats.lastVersion +
              "/all"
            }
          >
            <Tag color={"volcano"}>{stats.lastVersion}</Tag>
          </a>
        </p>
      </div>
      {
        <div style={{ padding: "0px 24px 24px 24px", backgroundColor: "#fff" }}>
          <h3>You are {stats.numOfVisitor} visitor</h3>
          <h3>Visitors online: {stats.numOfSessions}</h3>
        </div>
      }
    </>
  );
};
