import configData from "../../config.json";

export function getStatisticsGrowth(setLoading: any, setData: any) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(configData.SERVER_URL + "api/get/statistics/", requestOptions)
    .then((response) => response.json())
    .then((response) => {
      const chart = [].concat(response.results.chart);
      const all = [].concat(response.results.full);
      setData({
        all: all,
        chart: chart,
      });
      setLoading(false);
    })
    .catch((error) => console.log(error.message));
}

export function getStatisticsGrowthVersion(setData: any) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(configData.SERVER_URL + "api/get/version/statistics/", requestOptions)
    .then((response) => response.json())
    .then((response) => {
      setData({
        all: response.results,
      });
    })
    .catch((error) => console.log(error.message));
}

export function getStructureBelongingness(setLoading: any, setData: any) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(
    configData.SERVER_URL + "api/get/structure/belongingness/",
    requestOptions
  )
    .then((response) => response.json())
    .then((response) => {
      setData({
        table: response.results.table,
        chart: response.results.chart,
      });
      setLoading(false);
    })
    .catch((error) => console.log(error.message));
}

export function getStatisticsEqCardinality(setLoading: any, setData: any) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(configData.SERVER_URL + "api/get/cardinality/count/", requestOptions)
    .then((response) => response.json())
    .then((response) => {
      setData({
        result: response.results,
      });
      setLoading(false);
    })
    .catch((error) => console.log(error.message));
}
