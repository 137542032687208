import { Table, Tag } from "antd";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const { Column, ColumnGroup } = Table;

export function UpdateLogTable(data: any, loading: any, isDesktop: any) {
  const renderDetailsAdd = (text: any) => {
    let output = [];
    output.push(
      <tr style={{ fontWeight: "bolder" }}>
        <td width={"200px"}>Eq. class</td>
        <td width={"200px"}>Representative</td>
        <td width={"150px"}>Members</td>
        <td></td>
      </tr>
    );
    for (let i in text) {
      output.push(
        <tr>
          <td width={"200px"}>
            <a
              href={
                "http://rna.bgsu.edu/rna3dhub/nrlist/view/NR_all_" +
                text[i].eq_class
              }
            >
              <Tag color={"volcano"} key={text}>
                {text[i].eq_class}
              </Tag>
            </a>
          </td>
          <td width={"200px"}>
            {text[i].representative.replaceAll("+", " + ")}
          </td>
          <td width={"150px"}>{text[i].member_count}</td>
          <td></td>
        </tr>
      );
    }
    if (text.length > 0) {
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <h3 style={{ margin: "0px" }}>✅ New classes:</h3>
          </AccordionSummary>
          <AccordionDetails>
            <table style={{ textAlign: "left" }}>{output}</table>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return <div></div>;
    }
  };
  const renderDetailsUpdate = (text: any) => {
    let output = [];
    output.push(
      <tr style={{ fontWeight: "bolder" }}>
        <td width={"200px"}>Eq. class</td>
        <td width={"200px"}>Representative</td>
        <td width={"150px"}>Members</td>
        <td>Added/Deleted structures</td>
      </tr>
    );
    for (let i in text) {
      let foo = [];
      for (let j in text[i].added_members) {
        Number(j) === text[i].added_members.length - 1
          ? foo.push(
              <span>
                {text[i].added_members[j].structure}&nbsp;{"(A) "}
              </span>
            )
          : foo.push(
              <span>
                {text[i].added_members[j].structure}&nbsp;{"(A), "}
              </span>
            );
      }
      for (let j in text[i].deleted_members) {
        Number(j) === text[i].deleted_members.length - 1
          ? foo.push(
              <span>
                {text[i].deleted_members[j].structure}&nbsp;{"(D) "}
              </span>
            )
          : foo.push(
              <span>
                {text[i].deleted_members[j].structure}&nbsp;{"(D), "}
              </span>
            );
      }
      output.push(
        <tr>
          <td width={"200px"}>
            <a
              href={
                "http://rna.bgsu.edu/rna3dhub/nrlist/view/NR_all_" +
                text[i].eq_class
              }
            >
              <Tag color={"volcano"} key={text}>
                {text[i].eq_class}
              </Tag>
            </a>
          </td>
          <td width={"200px"}>
            {text[i].representative.replaceAll("+", " + ")}
          </td>
          <td width={"150px"}>{text[i].member_count}</td>
          {Number(i) === text.length - 1 ? (
            <td>{foo}</td>
          ) : (
            <td style={{ borderBottom: "1px gray dashed" }}>{foo}</td>
          )}
        </tr>
      );
    }
    if (text.length > 0) {
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <h3 style={{ margin: "0px" }}>💬 Updated classes:</h3>
          </AccordionSummary>
          <AccordionDetails>
            <table style={{ textAlign: "left" }}>{output}</table>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return <div></div>;
    }
  };
  const renderDetailsDelete = (text: any) => {
    let output = [];
    output.push(
      <tr style={{ fontWeight: "bolder" }}>
        <td width={"200px"}>Eq. class</td>
        <td width={"200px"}>Representative</td>
        <td width={"150px"}>Members</td>
        <td></td>
      </tr>
    );
    for (let i in text) {
      output.push(
        <tr>
          <td width={"200px"}>
            <a
              href={
                "http://rna.bgsu.edu/rna3dhub/nrlist/view/NR_all_" +
                text[i].eq_class
              }
            >
              <Tag color={"volcano"} key={text}>
                {text[i].eq_class}
              </Tag>
            </a>
          </td>
          <td width={"200px"}>
            {text[i].representative.replaceAll("+", " + ")}
          </td>
          <td width={"150px"}>{text[i].member_count}</td>
          <td></td>
        </tr>
      );
    }
    if (text.length > 0) {
      return (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            <h3 style={{ margin: "0px" }}>❌ Deleted classes:</h3>
          </AccordionSummary>
          <AccordionDetails>
            <table style={{ textAlign: "left" }}>{output}</table>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return <div></div>;
    }
  };
  const renderDetails = (text: any) => {
    let output = [
      renderDetailsAdd(text.added_eq_classes),
      renderDetailsUpdate(text.updated_eq_classes),
      renderDetailsDelete(text.deleted_eq_classes),
    ];
    if (isDesktop) {
      return <div>{output}</div>;
    } else {
      return <div style={{ minWidth: "700px" }}>{output}</div>;
    }
  };
  let bgsuHeaderTitle = "BGSU version:\nprevious -> new";
  return (
    <Table
      dataSource={data.all}
      style={{ padding: !isDesktop ? 0 : "" }}
      scroll={{ y: !isDesktop ? 400 : "", x: 1200 }}
      loading={loading}
    >
      <Column
        width={"150px"}
        title="Update time"
        dataIndex="timestamp"
        key="timestamp"
      />
      <Column
        title={bgsuHeaderTitle}
        key="update"
        width={150}
        render={(text: any) => text.old_version + " -> " + text.new_version}
      />
      <ColumnGroup title="Number of equivalence classes">
        <Column
          title="New"
          dataIndex="new_count"
          key="new_count"
          width={90}
          sorter={{
            compare: (a: any, b: any) => a.new_count - b.new_count,
            multiple: 1,
          }}
        />
        <Column
          title="Updated"
          dataIndex="updated_count"
          key="updated_count"
          width={90}
          sorter={{
            compare: (a: any, b: any) => a.updated_count - b.updated_count,
            multiple: 1,
          }}
        />
        <Column
          title="Deleted"
          dataIndex="deleted_count"
          key="deleted_count"
          width={90}
          sorter={{
            compare: (a: any, b: any) => a.deleted_count - b.deleted_count,
            multiple: 1,
          }}
        />
      </ColumnGroup>
      <Column
        title="Details"
        key="details"
        render={(text: any) => renderDetails(text)}
      />
    </Table>
  );
}
