import configData from "../../config.json";

export function getCookie() {
  let cookieValue = null;

  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      // Does this cookie string begin with the name we want?
      // eslint-disable-next-line
      if (cookie.substring(0, "csrftoken".length + 1) === "csrftoken" + "=") {
        cookieValue = decodeURIComponent(
          cookie.substring("csrftoken".length + 1)
        );

        break;
      }
    }
  }
  return String(cookieValue);
}

export const checkIfLogged = (setUserLogged: any, setConfigs: any) => {
  fetch(configData.SERVER_URL + "api/admin/islogged/", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-CSRFToken": getCookie(),
    },
    body: JSON.stringify({}),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.success === "yes") {
        getConfigs(setConfigs);
      }
      setUserLogged(response.success === "yes");
    })
    .catch((error) => console.log(error.message));
};

export const getConfigs = (setConfig: any) => {
  fetch(configData.SERVER_URL + "api/admin/get/config/", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-CSRFToken": getCookie(),
    },
    body: JSON.stringify({}),
  })
    .then((response) => response.json())
    .then((response) => {
      setConfig(response);
    })
    .catch((error) => console.log(error.message));
};

export const handleButtonAction = (link: any, config: any, setAlert: any) => {
  fetch(configData.SERVER_URL + link, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-CSRFToken": getCookie(),
    },
    body: JSON.stringify({}),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.process === "started") {
        setAlert({
          show: true,
          text: "Procedure has been started",
          isError: false,
          lockButtons: true,
        });
      } else {
        setAlert({
          show: true,
          text: "Something went wrong",
          isError: true,
          lockButtons: false,
        });
      }
    })
    .catch((error) => console.log(error.message));
};

export const sendPdbPeriodConfig = (config: any, setAlert: any) => {
  fetch(configData.SERVER_URL + "api/admin/set/pdbtime/", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-CSRFToken": getCookie(),
    },
    body: JSON.stringify({
      pdb_update_minute: config.pdb_update_minute,
      pdb_update_hour: config.pdb_update_hour,
      pdb_update_day_of_week: config.pdb_update_day_of_week,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.process === "started") {
        setAlert({
          show: true,
          text: "PDB update time has been set properly",
          isError: false,
          lockButtons: false,
        });
      } else {
        setAlert({
          show: true,
          text: "Something went wrong",
          isError: true,
          lockButtons: false,
        });
      }
    })
    .catch((error) => console.log(error.message));
};

export const sendBgsuPeriodConfig = (config: any, setAlert: any) => {
  fetch(configData.SERVER_URL + "api/admin/set/bgsutime/", {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-CSRFToken": getCookie(),
    },
    body: JSON.stringify({
      bgsu_update_minute: config.bgsu_update_minute,
      bgsu_update_hour: config.bgsu_update_hour,
      bgsu_update_day_of_week: config.bgsu_update_day_of_week,
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.process === "started") {
        setAlert({
          show: true,
          text: "BGSU update time has been set properly",
          isError: false,
          lockButtons: false,
        });
      } else {
        setAlert({
          show: true,
          text: "Something went wrong",
          isError: true,
          lockButtons: false,
        });
      }
    })
    .catch((error) => console.log(error.message));
};
