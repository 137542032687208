import {
  Bar,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine,
  XAxis,
  YAxis,
  BarChart,
} from "recharts";
import { CustomYAxis } from "../../../styles/Statistics/CustomYAxis";
import React from "react";

export function MonthGrowthMemberChart(data: any, isDesktop: any) {
  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={350}>
      <BarChart
        width={800}
        height={500}
        data={data.chart}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis height={70} dataKey="timestamp_chart">
          {/*<Label*/}
          {/*    value={'Month'}*/}
          {/*    position='outside'*/}
          {/*    fill='#676767'*/}
          {/*    style={{fontWeight: 700}}*/}
          {/*    fontSize={16}*/}

          {/*/>*/}
        </XAxis>
        {isDesktop ? (
          <YAxis
            width={140}
            tick={<CustomYAxis color={"#676767"} orientation={"left"} />}
          >
            <Label
              value={"Number of structures"}
              angle={-90}
              position="center"
              fill="#676767"
              style={{ fontWeight: 700 }}
              fontSize={16}
            />
          </YAxis>
        ) : (
          ""
        )}
        {isDesktop ? (
          <YAxis
            width={140}
            yAxisId="right"
            tick={<CustomYAxis color={"#676767"} />}
            orientation="right"
          />
        ) : (
          ""
        )}
        <Tooltip
          formatter={(value: any) => new Intl.NumberFormat("en").format(value)}
        />
        <Legend />
        <ReferenceLine y={0} stroke="#000" />
        <Bar
          dataKey="growth_solo_molecule"
          name={"Solo RNA"}
          fill={"#343A40"}
        />
        <Bar
          dataKey="growth_complex_molecule"
          name={"Protein-RNA Complex"}
          fill={"#28AFB0"}
        />

        <Bar
          dataKey="growth_hybrid_molecule"
          name={"DNA-RNA Hybrid"}
          fill={"#E2475D"}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
