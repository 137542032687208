import { Table } from "antd";
import "../../../styles/Statistics/Statistics.css";

const { Column, ColumnGroup } = Table;

export function MonthTable(data: any, loading: any, isDesktop: any) {
  return (
    <Table
      dataSource={data.all}
      id={"month-table"}
      style={isDesktop ? { padding: "auto" } : { padding: 0 }}
      scroll={isDesktop ? { x: "auto" } : { x: "100%" }}
      loading={loading}
    >
      <Column title="Month" dataIndex="timestamp_table" key="timestamp" />
      <ColumnGroup title="Equivalence class">
        <Column
          title="Total number"
          dataIndex={["stats", "eq_class_count"]}
          key="['stats','eq_class_count']"
          sorter={{
            compare: (a: any, b: any) =>
              a.stats.eq_class_count - b.stats.eq_class_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_eq"
          key="growth_eq"
          sorter={{
            compare: (a: any, b: any) => a.growth_eq - b.growth_eq,
            multiple: 1,
          }}
        />
      </ColumnGroup>
      <ColumnGroup title="Class member">
        <Column
          title="Total number"
          dataIndex={["stats", "member_count"]}
          key="['stats','member_count']"
          sorter={{
            compare: (a: any, b: any) =>
              a.stats.member_count - b.stats.member_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_member"
          key="growth_member"
          sorter={{
            compare: (a: any, b: any) => a.growth_member - b.growth_member,
            multiple: 1,
          }}
        />
      </ColumnGroup>
      <ColumnGroup title="Protein-RNA Complex">
        <Column
          title="Total number"
          dataIndex={["stats", "complex_molecule_count"]}
          key="['stats','complex_molecule_count']"
          sorter={{
            compare: (a: any, b: any) =>
              a.stats.complex_molecule_count - b.stats.complex_molecule_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_complex_molecule"
          key="complex_molecule_count"
          sorter={{
            compare: (a: any, b: any) =>
              a.growth_complex_molecule - b.growth_complex_molecule,
            multiple: 1,
          }}
        />
      </ColumnGroup>
      <ColumnGroup title="DNA-RNA Hybrid">
        <Column
          title="Total number"
          dataIndex={["stats", "hybrid_molecule_count"]}
          key="['stats','hybrid_molecule_count']"
          sorter={{
            compare: (a: any, b: any) =>
              a.stats.hybrid_molecule_count - b.stats.hybrid_molecule_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_hybrid_molecule"
          key="growth_hybrid_molecule"
          sorter={{
            compare: (a: any, b: any) =>
              a.growth_hybrid_molecule - b.growth_hybrid_molecule,
            multiple: 1,
          }}
        />
      </ColumnGroup>
      <ColumnGroup title="Solo RNA">
        <Column
          title="Total number"
          dataIndex={["stats", "solo_molecule_count"]}
          key="['stats','solo_molecule_count']"
          sorter={{
            compare: (a: any, b: any) =>
              a.stats.solo_molecule_count - b.stats.solo_molecule_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_solo_molecule"
          key="growth_solo_molecule"
          sorter={{
            compare: (a: any, b: any) =>
              a.growth_solo_molecule - b.growth_solo_molecule,
            multiple: 1,
          }}
        />
      </ColumnGroup>
    </Table>
  );
}
