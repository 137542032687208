import {
  handleButtonAction,
  sendBgsuPeriodConfig,
  sendPdbPeriodConfig,
} from "../../../adapters/adminAdapter";
import { Alert, Button, Divider, Form, Input, Table } from "antd";
import isNumeric from "antd/es/_util/isNumeric";

const { Column } = Table;

export function Dashboard(
  configs: any,
  setConfigs: any,
  formItemLayout: any,
  formLayout: any,
  alert: any,
  setAlert: any
) {
  return (
    <div style={{ width: "100%" }}>
      <Alert
        message={alert.isError ? "Error" : "Informational Notes"}
        description={alert.text}
        type={alert.isError ? "error" : "info"}
        style={alert.show ? {} : { display: "none" }}
        showIcon
        closable
      />
      <a href={"/admin/logout/"}>
        <Button style={{ margin: "10px" }} type={"primary"}>
          Logout
        </Button>
      </a>
      <a href={"/admin/"}>
        <Button style={{ margin: "10px" }} type={"primary"}>
          Django admin panel
        </Button>
      </a>
      <a href={"/admin/password_change/"}>
        <Button style={{ margin: "10px" }} type={"primary"}>
          Change password
        </Button>
      </a>
      <br />
      <Divider />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "row",
        }}
      >
        <div style={{ padding: "10px", width: "100%" }}>
          <Form
            {...formItemLayout}
            layout={formLayout}
            name="advanced_search"
            className="ant-advanced-search-form"
          >
            <h2>BGSU periodic update</h2>
            <br />
            <table width={"95%"}>
              <tr>
                <td width={"20%"} style={{ textAlign: "right" }}>
                  Day of week:
                </td>
                <td width={"50%"}>
                  <Input
                    placeholder={'Use "*" for "all". (Example: "0,5")'}
                    value={configs.bgsu_update_day_of_week}
                    onChange={(event) =>
                      setConfigs({
                        ...configs,
                        bgsu_update_day_of_week: event.target.value,
                      })
                    }
                  />
                </td>
                <td></td>
              </tr>

              <tr>
                <td width={"20%"} style={{ textAlign: "right" }}>
                  Hour:
                </td>
                <td width={"50%"}>
                  <Input
                    placeholder={'Use "*" for "all". (Example: "0,5")'}
                    value={configs.bgsu_update_hour}
                    onChange={(event) =>
                      setConfigs({
                        ...configs,
                        bgsu_update_hour: event.target.value,
                      })
                    }
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td width={"20%"} style={{ textAlign: "right" }}>
                  Minute:
                </td>
                <td width={"50%"}>
                  <Input
                    placeholder={'Use "*" for "all". (Example: "0,5")'}
                    value={configs.bgsu_update_minute}
                    onChange={(event) =>
                      setConfigs({
                        ...configs,
                        bgsu_update_minute: event.target.value,
                      })
                    }
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td width={"20%"}></td>
                <td width={"50%"}>
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      if (
                        isNumeric(configs.bgsu_update_minute) &&
                        isNumeric(configs.bgsu_update_hour) &&
                        isNumeric(configs.bgsu_update_day_of_week) &&
                        configs.bgsu_update_minute.length > 0 &&
                        configs.bgsu_update_hour.length > 0 &&
                        configs.bgsu_update_day_of_week.length > 0
                      ) {
                        sendBgsuPeriodConfig(configs, setAlert);
                      } else {
                        setAlert({
                          show: true,
                          isError: true,
                          text: "Fields are empty or the text is not a number",
                          lockButtons: false,
                        });
                      }
                    }}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Save
                  </Button>
                </td>
                <td></td>
              </tr>
            </table>
          </Form>
          <Form
            {...formItemLayout}
            layout={formLayout}
            name="advanced_search"
            className="ant-advanced-search-form"
          >
            <h2 style={{ marginTop: "20px" }}>PDB periodic update</h2>
            <br />
            <table width={"95%"}>
              <tr>
                <td width={"20%"} style={{ textAlign: "right" }}>
                  Day of week:
                </td>
                <td width={"50%"}>
                  <Input
                    placeholder={'Use "*" for "all". (Example: "0,5")'}
                    value={configs.pdb_update_day_of_week}
                    onChange={(event) =>
                      setConfigs({
                        ...configs,
                        pdb_update_day_of_week: event.target.value,
                      })
                    }
                  />
                </td>
                <td></td>
              </tr>

              <tr>
                <td width={"20%"} style={{ textAlign: "right" }}>
                  Hour:
                </td>
                <td width={"50%"}>
                  <Input
                    placeholder={'Use "*" for "all". (Example: "0,5")'}
                    value={configs.pdb_update_hour}
                    onChange={(event) =>
                      setConfigs({
                        ...configs,
                        pdb_update_hour: event.target.value,
                      })
                    }
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td width={"20%"} style={{ textAlign: "right" }}>
                  Minute:
                </td>
                <td width={"50%"}>
                  <Input
                    placeholder={'Use "*" for "all". (Example: "0,5")'}
                    value={configs.pdb_update_minute}
                    onChange={(event) =>
                      setConfigs({
                        ...configs,
                        pdb_update_minute: event.target.value,
                      })
                    }
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td width={"20%"}></td>
                <td width={"50%"}>
                  <Button
                    style={{ marginTop: "20px" }}
                    onClick={() => {
                      if (
                        isNumeric(configs.pdb_update_minute) &&
                        isNumeric(configs.pdb_update_hour) &&
                        isNumeric(configs.pdb_update_day_of_week) &&
                        configs.pdb_update_minute.length > 0 &&
                        configs.pdb_update_hour.length > 0 &&
                        configs.pdb_update_day_of_week.length > 0
                      ) {
                        sendPdbPeriodConfig(configs, setAlert);
                      } else {
                        setAlert({
                          show: true,
                          isError: true,
                          text: "Fields are empty or the text is not a number",
                          lockButtons: false,
                        });
                      }
                    }}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Save
                  </Button>
                </td>
                <td></td>
              </tr>
            </table>
          </Form>
        </div>
        <div style={{ padding: "10px" }}>
          {configs.set ? (
            <div>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/update/bgsu/",
                    configs,
                    setAlert
                  );
                }}
              >
                Manual BGSU update
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/update/pdb/",
                    configs,
                    setAlert
                  );
                }}
              >
                Manual PDB update
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/create/zip/",
                    configs,
                    setAlert
                  );
                }}
              >
                Remake archives
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/get/all/cif/",
                    configs,
                    setAlert
                  );
                }}
              >
                Download and process CIF
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/get/all/pdb/",
                    configs,
                    setAlert
                  );
                }}
              >
                Download and process PDB
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/get/all/render/",
                    configs,
                    setAlert
                  );
                }}
              >
                Render structures
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction("api/admin/check/", configs, setAlert);
                }}
              >
                Check file integrity
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/remove/disconnected/structures/",
                    configs,
                    setAlert
                  );
                }}
              >
                Remove disconnected structures
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/create/packages/",
                    configs,
                    setAlert
                  );
                }}
              >
                Create single PDB packages
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/get/all/structure/name/",
                    configs,
                    setAlert
                  );
                }}
              >
                Get all structure source name
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/get/all/structure/residue/number/",
                    configs,
                    setAlert
                  );
                }}
              >
                Get all structure residue number
              </Button>
              <Button
                type={"primary"}
                disabled={alert.lockButtons}
                style={{ margin: "10px" }}
                onClick={() => {
                  handleButtonAction(
                    "api/admin/get/all/fasta/",
                    configs,
                    setAlert
                  );
                }}
              >
                Get all FASTA
              </Button>
            </div>
          ) : (
            <Button
              type={"primary"}
              disabled={alert.lockButtons}
              style={{ margin: "10px" }}
              onClick={() => {
                handleButtonAction("api/admin/setup", configs, setAlert);
              }}
            >
              Setup server
            </Button>
          )}
          <h2>Error log</h2>
          <Table dataSource={configs.log}>
            <Column title="Error" dataIndex="0" key="0" />
            <Column title="Description" dataIndex="1" key="1" />
            <Column title="Timestamp" dataIndex="2" key="2" />
          </Table>
        </div>
      </div>
    </div>
  );
}
