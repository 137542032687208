import { Content } from "antd/es/layout/layout";
import { useMediaQuery } from "react-responsive";

export default function CiteUs() {
  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });

  let renderContent = () => {
    return (
      <Content
        style={{ padding: isDesktop ? "0 24px" : "0 0", minHeight: 280 }}
      >
        <div className="site-layout-content">
          <h1>Cite us</h1>
          <div style={{ textAlign: "justify" }}>
            <h3>
              In any published work which has made use of the repository, please
              cite the following paper:
              <br />
              <br />
                <b>B. Adamczyk, M. Antczak, M. Szachniuk</b> (2022) RNAsolo: a repository of
                clean, experimentally determined RNA 3D structures, Bioinformatics
                38(14):3668-3670 (doi: 10.1093/bioinformatics/btac386).
            </h3>
          </div>
        </div>
      </Content>
    );
  };
  return renderContent();
}
