import { useEffect, useState } from "react";
import { getStats } from "../../../adapters/appAdapter";

export function WelcomeText() {
  let dataStats = {
    lastVersion: "",
    numOfStructures: "",
    numOfRepresentatives: "",
    numOfVisitor: "",
    numOfSessions: "",
    versionDate: "",
  };
  const [stats, setStats] = useState(dataStats);

  useEffect(() => {
    getStats(setStats);
  }, []);
  return (
    <div style={{ fontSize: "18px", textAlign: "justify" }}>
      <span style={{ fontStyle: "italic" }}>RNAsolo</span> is a self-updating
      database that collects experimentally determined 3D RNA structures, cleans
      them from non-RNA data, and groups them into equivalence classes. After
      the recent update (
      <span style={{ fontWeight: "bold" }}>{stats.versionDate}</span>),{" "}
      <span style={{ fontStyle: "italic" }}>RNAsolo</span> contains{" "}
      <span style={{ fontWeight: "bold" }}>{stats.numOfStructures}</span> RNA
      structures clustered into{" "}
      <span style={{ fontWeight: "bold" }}>{stats.numOfRepresentatives}</span>{" "}
      equivalence classes - each of the latter is exemplified by a cluster
      representative.
    </div>
  );
}
