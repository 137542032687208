import {
  Alert,
  Button,
  Checkbox,
  Input,
  message,
  Radio,
  Table,
  Tabs,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import { getDesktopColumns } from "../../components/searchStructureComponents/searchResultTable/desktopColumns";
import {
  getArchivedClasses,
  getArchivedStructures,
  getSearchData,
} from "../../adapters/searchStructureAdapter";
import { useMediaQuery } from "react-responsive";
import "../../styles/SearchStructures/SearchStructres.css";
import { SearchOutlined } from "@ant-design/icons";
import { search_result } from "../../types";
import TextArea from "antd/lib/input/TextArea";
const { TabPane } = Tabs;
export default function StructureSearch() {
  let dataState = {
    data: [],
    not_found: [],
    found: -1,
  };

  let limit_state = {
    min: 1,
    max: 1000,
  };
  let [loading, setLoading] = useState(false);
  let [structureLoading, setStructureLoading] = useState(false);
  let [showTable, setShowTable] = useState(false);
  let [classPdbZip, setClassPdbZip] = useState(false);
  let [structurePdbZip, setStructurePdbZip] = useState(false);
  let [selectedKeys, setSelectedKeys] = useState([] as any);
  let [keysEnabled, setKeysEnabled] = useState(true);
  let [, setResultLink] = useState("");
  let [choseRadio, setChoseRadio] = useState(1);
  let [searchName, setSearchName] = useState("Search");
  let [limit, setLimit] = useState(limit_state);
  let [pdbs, setPDBs] = useState("");
  let [keywords, setKeywords] = useState("");
  let [searchCheckBox, setSearchCheckBox] = useState({
    keywords: false,
    limits: false,
  });
  const [data, setData] = useState(dataState);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: search_result[]) => {
      setSelectedKeys({ keys: selectedRowKeys });
      let structure_pdb = false;
      let class_pdb = false;

      for (let item of selectedRows) {
        if (item.pdb_structure) {
          structure_pdb = true;
        }
      }
      for (let item of selectedRows) {
        if (item.pdb_zip_eq) {
          class_pdb = true;
        }
      }
      setKeysEnabled(true);
      setClassPdbZip(class_pdb);
      setStructurePdbZip(structure_pdb);
    },
  };
  const [selectionType] = useState<"checkbox" | "radio">("checkbox");
  const onClear = (tabName: any = 1) => {
    setKeywords("");
    setPDBs("");
    setChoseRadio(1);
    setLimit({ min: 1, max: 1000 });
    if (tabName === '1') {
      setSearchCheckBox({
        keywords: false,
        limits: false,
      });
    } else if (tabName === '2') {
      setSearchCheckBox({
        keywords: true,
        limits: false,
      });
    }
  };
  function callback(x: any) {
    onClear(x);
  }
  const onSearch = (field: number) => {
    let temp_splited_keywords = keywords.split(";");
    let temp_splited_pdbs = pdbs.replaceAll(" ", "").split(";");

    let limit_min = limit.min;
    let limit_max = limit.max;
    let choseRadioTemp = choseRadio;
    if (
      (field === 1 &&
        temp_splited_pdbs.length === 1 &&
        temp_splited_pdbs[0] === "") || (temp_splited_pdbs.length === 0 && field === 1)
    ) {
      message.error("Query undefined. Enter search criterion.");
      return;
    }
    // if (
    //   searchCheckBox.keywords &&
    //   temp_splited_keywords.length === 1 &&
    //   temp_splited_keywords[0] === ""
    // ) {
    //   message.error("Query undefined. Enter search criterion.");
    //   return;
    // }
    if (!searchCheckBox.keywords) {
      temp_splited_keywords = [""];
    }
    if (searchCheckBox.limits && !searchCheckBox.keywords) {
      choseRadioTemp = 2;
    }
    if (
      (!searchCheckBox.limits && searchCheckBox.keywords) ||
      (!searchCheckBox.limits && !searchCheckBox.keywords)
    ) {
      choseRadioTemp = 1;
    }

    if (isNaN(limit_min)) {
      limit_min = 1
    }
    if (isNaN(limit_max)) {
      limit_max = 99999
    }
    if (!searchCheckBox.limits) {
      limit_min = 1;
      limit_max = 99999;
    }
    for (let i = 0; i < temp_splited_keywords.length; i++) {
      if (
        temp_splited_keywords.length === 1 &&
        temp_splited_keywords[0] === ""
      ) {
      } else if (
        (temp_splited_keywords[i].trim().length < 3 ||
          temp_splited_keywords[i].trim().length > 300) && temp_splited_keywords[i].trim().length !== 0
      ) {
        // console.log(temp_splited_keywords[i].trim().length)
        // console.log(temp_splited_keywords[i])
        message.error("Keyword with invalid length. Please correct.");
        return;
      } else {
        temp_splited_keywords[i] = temp_splited_keywords[i].trim();
      }
    }

    for (let i = temp_splited_pdbs.length - 1; i >= 0; i--) {
      if (temp_splited_pdbs.length === 1 && temp_splited_pdbs[0] === "") {
      } else if (temp_splited_pdbs[i].length !== 4 && temp_splited_pdbs[i].length !== 0) {
        message.error("Invalid PDB ID. Please correct.");
        return;
      }
    }
    temp_splited_pdbs = temp_splited_pdbs.filter(function (value, index, arr) {
      return value.length === 4;
    });

    temp_splited_keywords = temp_splited_keywords.filter(function (value, index, arr) {
      return value.length > 2 && value.length < 301;
    });
    // console.log(field)
    if (
      (searchCheckBox.keywords && field === 2 &&
        temp_splited_keywords.length === 1 &&
        temp_splited_keywords[0] === "") || (temp_splited_keywords.length === 0 && searchCheckBox.keywords && field === 2)
    ) {
      message.error("Query undefined. Enter search criterion.");
      return;
    }
    if (limit_min < 1 || limit_max < 1 || limit_max < limit_min) {
      message.error("Invalid min/max value. Please correct.");
      return;
    }

    setShowTable(true);
    setLoading(true);
    getSearchData(
      setData,
      choseRadioTemp,
      temp_splited_keywords,
      temp_splited_pdbs,
      limit_min,
      limit_max,
      setLoading
    );
    setSearchName("Search anew");
  };
  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });


  return (
    <Content style={{ padding: isDesktop ? "0 24px" : "0 0", minHeight: 280 }}>
      <div className="site-layout-content">
        <h1>Search the data</h1>
        <br />
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="PDB IDs" key="1">
            <h3> Enter 1-10 PDB identifiers (use semicolon as separator)</h3>
            <Input
              maxLength={60}
              placeholder={"5EL4; 3IGI"}
              id={"search_pdb"}
              allowClear
              onChange={(e) => setPDBs(e.target.value.toUpperCase().replace(',', ';'))}
              value={pdbs}
              disabled={loading}
              onPressEnter={() => onSearch(1)}
              style={{ marginBottom: "20px" }}
            />
            <Button
              type="primary"
              loading={loading}
              onClick={() => onSearch(1)}
              icon={<SearchOutlined />}
            >
              {searchName}
            </Button>
            &nbsp;
            <Button type="primary" disabled={loading} onClick={onClear}>
              Reset filter
            </Button>
          </TabPane>
          <TabPane tab="Keywords & Size" key="2">
            <Checkbox
              checked={searchCheckBox.keywords}
              style={{ width: "100%" }}
              disabled={loading}
              onChange={() =>
                setSearchCheckBox({
                  ...searchCheckBox,
                  keywords: !searchCheckBox.keywords,
                })
              }
            >
              <div>
                <h3>
                  Type 1-5 keywords, each 3-300 characters long (use semicolon
                  as separator)
                </h3>
                <h4>(keywords are searched in title and source)</h4>

                <TextArea
                  maxLength={1500}
                  placeholder={"MERS; SARS"}
                  id={"search_keyword"}
                  allowClear
                  onChange={(e) => setKeywords(e.target.value)}
                  value={keywords}
                  disabled={loading || !searchCheckBox.keywords}
                  onPressEnter={() => onSearch(2)}
                  style={{ marginBottom: "20px" }}
                />
              </div>
            </Checkbox>
            <br />
            <Radio.Group
              onChange={(e) => setChoseRadio(e.target.value)}
              value={choseRadio}
              style={{ marginBottom: "20px" }}
              disabled={
                loading || !searchCheckBox.limits || !searchCheckBox.keywords
              }
            >
              <Radio.Button value={1}>AND</Radio.Button>
              <Radio.Button value={2}>OR</Radio.Button>
            </Radio.Group>
            <br />
            <Checkbox
              style={{ width: "100%" }}
              checked={searchCheckBox.limits}
              disabled={loading}
              onChange={() =>
                setSearchCheckBox({
                  ...searchCheckBox,
                  limits: !searchCheckBox.limits,
                })
              }
            >
              <div>
                <h3>Set the limit for structure size (min, max, or both)</h3>
                <h4>(size = total number of nucleotides)</h4>
                min&nbsp;&nbsp;&nbsp;
                <Input
                  type={"number"}
                  id={"min_limit"}
                  min={1}
                  onChange={(e) =>
                    setLimit({ ...limit, min: parseInt(e.target.value) })
                  }
                  value={limit.min}
                  disabled={loading || !searchCheckBox.limits}
                  onPressEnter={() => onSearch(2)}
                  style={{ marginBottom: "20px", width: "100px" }}
                />
                <br />
                max&nbsp;&nbsp;
                <Input
                  type={"number"}
                  id={"max_limit"}
                  min={1}
                  onChange={(e) =>
                    setLimit({ ...limit, max: parseInt(e.target.value) })
                  }
                  value={limit.max}
                  disabled={loading || !searchCheckBox.limits}
                  onPressEnter={() => onSearch(2)}
                  style={{ marginBottom: "20px", width: "100px" }}
                />
              </div>
            </Checkbox>
            <Button
              type="primary"
              loading={loading}
              onClick={() => onSearch(2)}
              icon={<SearchOutlined />}
            >
              {searchName}
            </Button>
            &nbsp;
            <Button type="primary" disabled={loading} onClick={onClear}>
              Reset filters
            </Button>
          </TabPane>
        </Tabs>

      </div>
      {showTable ? (
        <div className="site-layout-content" style={{ marginTop: "10px" }}>
          <h3>
            {data.found > -1 ? (
              <>
                Results: <strong>{data.found.toString()}</strong> structure(s)
              </>
            ) : (
              <></>
            )}
          </h3>
          <div style={{ height: "144px" }}>
            <div
              style={{
                width: "50%",
                height: "100%",
                float: "left",
              }}
              className={"flex-center-column"}
            >

            </div>
            <div style={{ float: "right" }}>
              <div style={{ display: 'flex', flexDirection: 'column', float: "right" }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                  <div style={{ padding: "20px" }}>
                    <h3 style={{ margin: 0 }}>
                      Download selected structures
                    </h3>
                  </div>
                  <div>
                    <div style={{ padding: "20px",float:'left' }}>
                      <Button
                        type={"primary"}
                        disabled={!structurePdbZip || !keysEnabled}
                        onClick={() => {
                          setKeysEnabled(false);
                          getArchivedStructures(
                            selectedKeys,
                            "FASTA",
                            setStructureLoading,
                            setResultLink
                          );
                        }}
                      >
                        FASTA
                      </Button>
                    </div>
                    <div style={{ padding: "20px",float:'left' }}>
                      <Button
                        type={"primary"}
                        disabled={!structurePdbZip || !keysEnabled}
                        onClick={() => {
                          setKeysEnabled(false);
                          getArchivedStructures(
                            selectedKeys,
                            "PDB",
                            setStructureLoading,
                            setResultLink
                          );
                        }}
                      >
                        PDB
                      </Button>
                    </div>
                    <div style={{ padding: "20px",float:'left' }}>
                      <Button
                        type={"primary"}
                        disabled={
                          selectedKeys.keys.length < 1 || !keysEnabled
                        }
                        onClick={() => {
                          setKeysEnabled(false);
                          getArchivedStructures(
                            selectedKeys,
                            "CIF",
                            setStructureLoading,
                            setResultLink
                          );
                        }}
                      >
                        CIF
                      </Button>
                    </div>
                  </div>
                </div>
                <br />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
                  <div style={{ padding: "20px" }}>
                    <h3 style={{ margin: 0 }}>
                      Download classes for selected structures
                    </h3>
                  </div>
                  <div>
                    <div style={{ padding: "20px",float:'left' }}>
                      <Button
                        type={"primary"}
                        disabled={!classPdbZip || !keysEnabled}
                        onClick={() => {
                          setKeysEnabled(false);
                          getArchivedClasses(
                            selectedKeys,
                            "FASTA",
                            setStructureLoading,
                            setResultLink
                          );
                        }}
                      >
                        FASTA
                      </Button>
                    </div>
                    <div style={{ padding: "20px",float:'left' }}>
                      <Button
                        type={"primary"}
                        disabled={!classPdbZip || !keysEnabled}
                        onClick={() => {
                          setKeysEnabled(false);
                          getArchivedClasses(
                            selectedKeys,
                            "PDB",
                            setStructureLoading,
                            setResultLink
                          );
                        }}
                      >
                        PDB
                      </Button>
                    </div>
                    <div style={{ padding: "20px",float:'left' }}>
                      <Button
                        type={"primary"}
                        disabled={
                          selectedKeys.keys.length < 1 || !keysEnabled
                        }
                        onClick={() => {
                          setKeysEnabled(false);
                          getArchivedClasses(
                            selectedKeys,
                            "CIF",
                            setStructureLoading,
                            setResultLink
                          );
                        }}
                      >
                        CIF
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {structureLoading ? (
            <Alert
              message="Please wait"
              description="Selected structures are being prepared to download"
              type="info"
              showIcon
            />
          ) : null}
          <Table
            id={"search-structure-result-table"}
            loading={loading}
            style={{ padding: !isDesktop ? "0" : "" }}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "200", "1000"],
            }}
            scroll={{ x: 1000 }}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
              fixed: false,
            }}
            columns={getDesktopColumns()}
            dataSource={data.data}
          />
        </div>
      ) : null}
    </Content>
  );
}
