import { Table } from "antd";
import { JsonToCsvButton } from "../JsonToCsvButton";
import { renderClasses } from "../SharedTableElements/renderClassesList";

export function EqCardinalityTable(data: any, loading: any, isDesktop: any) {
  const eqCardinalityColumns = [
    {
      title: "Cardinality",
      dataIndex: "cardinality",
      key: "cardinality",
      sorter: {
        compare: (a: any, b: any) => a.cardinality - b.cardinality,
        multiple: 1,
      },
    },
    {
      title: "Number of classes",
      dataIndex: "count",
      key: "count",
      sorter: {
        compare: (a: any, b: any) => a.count - b.count,
        multiple: 1,
      },
    },
    {
      title: "Equivalence classes",
      className: "sorter-no-tooltip",
      width: "50%",
      dataIndex: "eq_classes",
      key: "eq_classes",
      render: (text: any) => renderClasses(text),
    },
  ];
  return (
    <>
      <Table
        style={isDesktop ? { minWidth: "100%" } : { padding: 0 }}
        scroll={isDesktop ? { x: "auto" } : { x: "100%" }}
        showSorterTooltip={false}
        dataSource={data.result}
        loading={loading}
        columns={eqCardinalityColumns}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {" "}
        {JsonToCsvButton(
          data.result,
          eqCardinalityColumns.forEach((x) => x.title),'rnasolo-class-card-stats'
        )}
      </div>
    </>
  );
}
