import { Content } from "antd/es/layout/layout";

import { Switch } from "antd";
import { Loading } from "antd-mobile";
import { useEffect, useState } from "react";
import {
  getStatisticsEqCardinality,
  getStatisticsGrowth,
  getStatisticsGrowthVersion,
  getStructureBelongingness,
} from "../../adapters/statisticsAdapter";
import { StructureAssignmentTable } from "../../components/statisticsComponents/StructureAssignmentTable";
import { MonthTable } from "../../components/statisticsComponents/MonthTable";
import { VersionTable } from "../../components/statisticsComponents/VersionTable";
import { JsonToCsvButton } from "../../components/statisticsComponents/JsonToCsvButton";
import { MonthEqChart } from "../../components/statisticsComponents/MonthEqChart";
import { MonthMemberChart } from "../../components/statisticsComponents/MonthMemberChart";
import { useMediaQuery } from "react-responsive";
import { MonthGrowthMemberChart } from "../../components/statisticsComponents/MonthGrowthMemberChart";
import { MonthGrowthEqChart } from "../../components/statisticsComponents/MonthGrowthEqChart";
import { BarChartOutlined, TableOutlined } from "@ant-design/icons";
import { StructureAssignmentChart } from "../../components/statisticsComponents/StructureAssignmentChart";
import { EqCardinalityChart } from "../../components/statisticsComponents/EqCardinalityChart";
import { EqCardinalityTable } from "../../components/statisticsComponents/EqCardinalityTable";

export default function Statistics() {
  let dataS = {
    all: [],
    chart: [],
  };
  let dataSV = {
    all: [],
  };
  let dataSBelongingness = {
    chart: [],
    table: [],
  };
  let dataEqCard = {
    result: [],
  };
  let searchState = {
    searchText: "",
    searchedColumn: "",
  };
  const [, setState] = useState(searchState);
  let [loading, setLoading] = useState(true);
  let [loadingBelongingness, setLoadingBelongingness] = useState(true);
  let [loadingEqCardinality, setLoadingEqCardinality] = useState(true);
  let [data, setData] = useState(dataS);
  let [dataV, setDataV] = useState(dataSV);
  let [dataSB, setDataSB] = useState(dataSBelongingness);
  let [dataEqCardinality, setDataEqCardinality] = useState(dataEqCard);
  let [checkedSwitch, setSwitchState] = useState(false);
  let [checkedBelongingnessSwitch, setSwitchBelongingnessState] = useState(
    false
  );
  let [checkedEqCardinalitySwitch, setSwitchEqCardinalityState] = useState(
    false
  );

  const onChangeSwitch = (checked: any) => {
    setSwitchState(checked);
  };
  const onChangeBelongingnessSwitch = (checked: any) => {
    setSwitchBelongingnessState(checked);
  };
  const onChangeEqCardinalitySwitch = (checked: any) => {
    setSwitchEqCardinalityState(checked);
  };
  useEffect(() => {
    getStatisticsGrowth(setLoading, setData);
    getStatisticsGrowthVersion(setDataV);
    getStructureBelongingness(setLoadingBelongingness, setDataSB);
    getStatisticsEqCardinality(setLoadingEqCardinality, setDataEqCardinality);
  }, []);
  let versionTableFields = [
    "timestamp",
    "version",
    "eq_class_count",
    "member_count",
    "growth_eq",
    "growth_member",
    "complex_molecule_count",
    "growth_complex_molecule",
    "hybrid_molecule_count",
    "growth_hybrid_molecule",
    "solo_molecule_count",
    "growth_solo_molecule",
  ];
  let monthTableFields = [
    "timestamp_chart",
    "growth_solo_molecule",
    "growth_complex_molecule",
    "growth_eq",
    "growth_hybrid_molecule",
    "growth_member",
    "stats.solo_molecule_count",
    "stats.complex_molecule_count",
    "stats.eq_class_count",
    "stats.hybrid_molecule_count",
    "stats.member_count",
  ];

  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });
  return (
    <Content style={{ padding: isDesktop ? "0 24px" : "0 0", minHeight: 280 }}>
      <div className="site-layout-content">
        <h1>Statistics</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              marginRight: "50px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "end",
            }}
          >
            <div>Chart</div>
            &nbsp;
            <Switch
              unCheckedChildren={<BarChartOutlined />}
              checkedChildren={<TableOutlined />}
              onChange={onChangeSwitch}
            />
            &nbsp;
            <div>Tabular</div>
          </div>
          {checkedSwitch ? (
            <>
              <h2 style={{ textAlign: "center" }}>Monthly statistics</h2>
              {MonthTable(data, loading, isDesktop)}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {" "}
                {JsonToCsvButton(data.all, monthTableFields,'rnasolo-monthly-stats')}
              </div>

              <hr style={{ margin: "50px", width: "100%" }} color={"#f0f0f0"} />
              <h2 style={{ textAlign: "center" }}>Version statistics</h2>
              {VersionTable(dataV, loading, isDesktop)}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {JsonToCsvButton(dataV.all, versionTableFields,'rnasolo-version-stats')}
              </div>
            </>
          ) : (
            <>
              <h2>RNAsolo structures</h2>
              {loading ? <Loading /> : MonthMemberChart(data, isDesktop)}
              <h3 style={{ marginTop: "50px" }}>Monthly growth</h3>
              {loading ? <Loading /> : MonthGrowthMemberChart(data, isDesktop)}
              <br />
              <hr style={{ margin: "50px", width: "100%" }} color={"#f0f0f0"} />

              <h2 style={{ marginTop: "20px" }}>RNAsolo equivalence classes</h2>
              {loading ? <Loading /> : MonthEqChart(data, isDesktop)}
              <br />
              <h3 style={{ marginTop: "50px" }}>Monthly growth</h3>
              {loading ? <Loading /> : MonthGrowthEqChart(data, isDesktop)}
            </>
          )}

          <br />
          <hr style={{ margin: "50px", width: "100%" }} color={"#f0f0f0"} />

          <h2 style={{ textAlign: "center", marginTop: "10px" }}>
            List of all structures with their assignments
          </h2>
          <p>Data collected from all structures, regardless of their resolution.</p>
          <div
            style={{
              width: "100%",
              marginRight: "50px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "end",
            }}
          >
            <div>Chart</div>
            &nbsp;
            <Switch
              unCheckedChildren={<BarChartOutlined />}
              checkedChildren={<TableOutlined />}
              onChange={onChangeBelongingnessSwitch}
            />
            &nbsp;
            <div>Tabular</div>
          </div>

          {checkedBelongingnessSwitch ? (
            StructureAssignmentTable(
              dataSB,
              loadingBelongingness,
              setState,
              isDesktop
            )
          ) : loadingBelongingness ? (
            <Loading />
          ) : (
            StructureAssignmentChart(dataSB, isDesktop)
          )}
          <hr style={{ margin: "50px", width: "100%" }} color={"#f0f0f0"} />

          <h2 style={{ textAlign: "center", marginTop: "10px" }}>
            Equivalence class cardinality statistics
          </h2>
          <p>Data collected from all structures, regardless of their resolution.</p>
          <div
            style={{
              width: "100%",
              marginRight: "50px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "end",
            }}
          >
            <div>Chart</div>
            &nbsp;
            <Switch
              unCheckedChildren={<BarChartOutlined />}
              checkedChildren={<TableOutlined />}
              onChange={onChangeEqCardinalitySwitch}
            />
            &nbsp;
            <div>Tabular</div>
          </div>

          {checkedEqCardinalitySwitch ? (
            EqCardinalityTable(
              dataEqCardinality,
              loadingEqCardinality,
              isDesktop
            )
          ) : loadingEqCardinality ? (
            <Loading />
          ) : (
            EqCardinalityChart(dataEqCardinality, isDesktop)
          )}
        </div>
      </div>
    </Content>
  );
}
