import React, { Component } from "react";

export class CustomYAxis extends Component<any> {
  render() {
    const { x, y, payload } = this.props;
    return (
      <>
        {this.props.orientation === "left" ? (
          <g>
            <text
              style={{ fill: this.props.color }}
              textAnchor="end"
              fontSize={14}
              x={x}
              y={y}
              dy={5}
            >
              {new Intl.NumberFormat("en").format(payload.value)}
            </text>
          </g>
        ) : (
          <g>
            <text
              style={{ fill: this.props.color }}
              fontSize={14}
              x={x}
              y={y}
              dy={5}
            >
              {new Intl.NumberFormat("en").format(payload.value)}
            </text>
          </g>
        )}
      </>
    );
  }
}
