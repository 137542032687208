import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { getUpdateLogData } from "../../adapters/updateLogAdapter";
import { UpdateLogTable } from "../../components/updateLogComponents/UpdateLogTable";
import { useMediaQuery } from "react-responsive";

export default function UpdateLog() {
  let dataS = {
    all: [],
  };
  let [loading, setLoading] = useState(true);
  let [data, setData] = useState(dataS);
  useEffect(() => {
    getUpdateLogData(setLoading, setData);
  }, []);
  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });
  return (
    <Content style={{ padding: isDesktop ? "0 24px" : "0 0", minHeight: 280 }}>
      <div className="site-layout-content" style={{ whiteSpace: "pre-line" }}>
        <h1>Update log</h1>
        <p>History of updates</p>
        {UpdateLogTable(data, loading, isDesktop)}
      </div>
    </Content>
  );
}
