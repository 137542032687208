import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import React from "react";

export const EqChartTooltip = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    // @ts-ignore
    let numberOnTooltip = new Intl.NumberFormat("en").format(payload[0].value);
    return (
      <div
        className="recharts-default-tooltip"
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: "#ffffff",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#cccccc",
          whiteSpace: "nowrap",
        }}
      >
        <p className="recharts-tooltip-label" style={{ margin: 0 }}>
          10/2020
        </p>
        <ul
          className="recharts-tooltip-item-list"
          style={{ padding: 0, margin: 0 }}
        >
          <li
            className="recharts-tooltip-item"
            style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}
          >
            <span className="recharts-tooltip-item-value">
              {numberOnTooltip}
            </span>
          </li>
        </ul>
      </div>
    );
  }
  return null;
};
export const EqChartTooltipWithX = ({
  active,
  payload,
  label,
}: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    // @ts-ignore
    let numberOnTooltip = new Intl.NumberFormat("en").format(payload[0].value);
    return (
      <div
        className="recharts-default-tooltip"
        style={{
          margin: 0,
          padding: 10,
          backgroundColor: "#ffffff",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: "#cccccc",
          whiteSpace: "nowrap",
        }}
      >
        <p className="recharts-tooltip-label" style={{ margin: 0 }}>
          10/2020
        </p>
        <ul
          className="recharts-tooltip-item-list"
          style={{ padding: 0, margin: 0 }}
        >
          <li
            className="recharts-tooltip-item"
            style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}
          >
            <span className="recharts-tooltip-item-value">
              Cardinality: {label}
            </span>
          </li>
          <li
            className="recharts-tooltip-item"
            style={{ display: "block", paddingTop: 4, paddingBottom: 4 }}
          >
            <span className="recharts-tooltip-item-value">
              Count: {numberOnTooltip}
            </span>
          </li>
        </ul>
      </div>
    );
  }
  return null;
};
