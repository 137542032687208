import Sider from "antd/es/layout/Sider";
import { Menu } from "antd";
import {
  BarChartOutlined,
  FileZipOutlined,
  HomeOutlined,
  QuestionOutlined,
  SearchOutlined,
  TableOutlined,
  TeamOutlined,
  InfoOutlined
} from "@ant-design/icons";
import pP from "../../../assets/home/PP-PUT_logo_jasne.png";
import iCHB from "../../../assets/home/ICHB_PAN_EN_kolor.png";
import RNApolis from "../../../assets/home/RNApolis-logo.png";
import { Stats } from "./stats";
import { useEffect, useState } from "react";
import { getStats } from "../../../adapters/appAdapter";
import { respository_stats } from "../../../types";

export const DesktopNavHeader = () => {
  
  let rep_stats: respository_stats = {
    lastVersion: "",
    numOfStructures: "",
    numOfRepresentatives: "",
    numOfVisitor: "",
    numOfSessions: "",
    versionDate: "",
  };
  const [stats, setStats] = useState(rep_stats);
  useEffect(() => {
    getStats(setStats);
  }, []);
  return (
    <Sider
      className="site-layout-background"
      style={{ background: "#00000000" }}
      width={250}
    >
      <Menu mode="inline" defaultOpenKeys={["sub"]}>
        <Menu.Item key="1" icon={<HomeOutlined />}>
          <a href={"/"}>Home page</a>{" "}
        </Menu.Item>
        <Menu.Item key="2" icon={<SearchOutlined />}>
          <a href={"/search"}>Search the data</a>
        </Menu.Item>
        <Menu.Item key="3" icon={<BarChartOutlined />}>
          <a href={"/statistics"}>Database statistics</a>
        </Menu.Item>
        <Menu.Item key="5" icon={<FileZipOutlined />}>
          <a href={"/archive"}>Download archive</a>
        </Menu.Item>
        <Menu.Item key="14" icon={<TableOutlined />}>
          <a href={"/updatelog"}>Update log</a>
        </Menu.Item>

        <Menu.Item key="15" icon={<TeamOutlined />}>
          <a href={"/about"}>About RNAsolo</a>
        </Menu.Item>
        <Menu.Item key="16" icon={<InfoOutlined  />}>
          <a href={"/cite"}>Cite us</a>
        </Menu.Item>
        <Menu.Item key="17" icon={<QuestionOutlined />}>
          <a href={"/help"}>Help</a>
        </Menu.Item>
      </Menu>

      {Stats(stats)}

      <br />
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://www.put.poznan.pl/index.php/en"}
        >
          <img
            alt={"PP logo"}
            style={{ marginTop: "50px", width: "90px" }}
            src={pP}
          />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href={"https://www.ibch.poznan.pl/en.html"}
        >
          <img
            alt={"IBCH logo"}
            style={{ marginTop: "50px", width: "90px" }}
            src={iCHB}
          />
        </a>
      </div>
      <a target="_blank" rel="noreferrer" href={"https://www.rnapolis.pl/"}>
        <img
          alt={"RNApolis"}
          style={{ margin: "10px", width: "230px", marginTop: "50px" }}
          src={RNApolis}
        />
      </a>
    </Sider>
  );
};
