import { Content } from "antd/es/layout/layout";
import { useMediaQuery } from "react-responsive";

export default function About() {
  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });

  let renderContent = () => {
    return (
      <Content
        style={{ padding: isDesktop ? "0 24px" : "0 0", minHeight: 280 }}
      >
        <div className="site-layout-content">
          <h1>About</h1>
          <div style={{ textAlign: "justify" }}>
            RNAsolo is a repository of clean, experimentally determined RNA 3D
            structures. Every week, they are automatically downloaded from the{" "}
            <a target="_blank" rel="noreferrer" href={"https://www.rcsb.org/"}>
              Protein Data Bank
            </a>
            , cleaned by removing non-RNA fragments, and assigned to equivalence
            classes (according to{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={"http://rna.bgsu.edu/rna3dhub/nrlist/"}
            >
              Representative Sets of RNA 3D Structures
            </a>
            ). The ability to easily download all deposited RNA structures or
            the equivalence class representatives makes RNAsolo an excellent
            resource for benchmarking bioinformatics tools and creating training
            sets for AI-based models and methods dedicated to RNA.
          </div>
          <br />
          <br />
          <h2>Team</h2>
          <a
            href="https://github.com/adamczykb"
            target="_blank"
            rel="noreferrer"
          >
            Bartosz&nbsp;Adamczyk
          </a>
          <sup>1</sup>,{" "}
          <a
            href="http://www.cs.put.poznan.pl/mantczak"
            target="_blank"
            rel="noreferrer"
          >
            Maciej Antczak
          </a>
          <sup>1,2</sup>,{" "}
          <a
            href="http://www.cs.put.poznan.pl/mszachniuk"
            target="_blank"
            rel="noreferrer"
          >
            Marta&nbsp;Szachniuk
          </a>
          <sup>1,2</sup>
          <br />
          <br />
          <ol>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://www2.cs.put.poznan.pl/en/"
              >
                Institute of Computing Science
              </a>
              &nbsp;&&nbsp;
              <a target="_blank" rel="noreferrer" href="http://www.ecbig.pl">
                European Centre for Bioinformatics and Genomics
              </a>
              ,
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.put.poznan.pl/index.php/en"
              >
                &nbsp;Poznan University of Technology
              </a>
              , 60-965 Poznan, Poland
            </li>

            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="http://www.ibch.poznan.pl/en/home/"
              >
                Institute of Bioorganic Chemistry
              </a>
              ,
              <a
                target="_blank"
                rel="noreferrer"
                href="http://institution.pan.pl/"
                title="Polish Academy of Sciences"
              >
                {" "}
                Polish Academy of Sciences
              </a>
              , 61-704 Poznan, Poland
            </li>
          </ol>
        </div>
      </Content>
    );
  };
  return renderContent();
}
