import configData from "../../config.json";

export const getStats = (setStats: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(configData.SERVER_URL + "api/version/", requestOptions)
    .then((response) => response.json())
    .then((response) => {
      setStats({
        lastVersion: response.bgsu_ver,
        numOfStructures: response.struct_count,
        numOfRepresentatives: response.repre_count,
        numOfVisitor: response.visitor_count,
        numOfSessions: response.session_count,
        versionDate: response.bgsu_date,
      });
    })
    .catch((error) => console.log(error.message));
};
export const isBusy = (setIsBusy: any) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(configData.SERVER_URL + "api/isbusy/", requestOptions)
    .then((response) => response.json())
    .then((response) => {
      setIsBusy(response.results);
    })
    .catch((error) => console.log(error.message));
};
