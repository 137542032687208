import {
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import React from "react";
import { CustomYAxis } from "../../../styles/Statistics/CustomYAxis";

function getRandomColor() {
  let letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function StructureAssignmentChart(data: any, isDesktop: any) {
  let colors: string[] = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "red",
    "pink",
  ];
  for (let i = 0; i < 20; i++) {
    colors.push(getRandomColor());
  }
  return (
    <ResponsiveContainer width="100%" height="100%" minHeight={350}>
      <ScatterChart
        width={800}
        height={500}
        data={data.chart}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid />
        <XAxis
          type="number"
          dataKey="class_member"
          name="Number of assigned classes"
          height={70}
          tick={<CustomYAxis color={"#676767"} orientation={"left"} />}
        >
          <Label
            value={"Number of assigned classes"}
            position="center"
            fill="#676767"
            style={{ fontWeight: 700 }}
            fontSize={16}
          />
        </XAxis>
        <YAxis
          type="number"
          dataKey="representative"
          name="Number of represented classes"
          width={isDesktop ? 140 : 0}
          tick={<CustomYAxis color={"#676767"} orientation={"left"} />}
        >
          {isDesktop ? (
            <Label
              value={"Number of represented classes"}
              angle={-90}
              position="center"
              fill="#676767"
              style={{ fontWeight: 700 }}
              fontSize={16}
            />
          ) : (
            ""
          )}
        </YAxis>
        {isDesktop ? (
          <YAxis
            width={140}
            yAxisId="right"
            tick={<CustomYAxis color={"#676767"} />}
            orientation="right"
          />
        ) : (
          ""
        )}
        <ZAxis
          type="number"
          dataKey="number_structres"
          range={[100, 400]}
          name="Number of structures"
        />
        <Tooltip cursor={{ strokeDasharray: "3 3" }} />
        <Scatter data={data.chart}>
          {data.chart.map((entry: any, index: any) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          ))}
        </Scatter>
      </ScatterChart>
    </ResponsiveContainer>
  );
}
