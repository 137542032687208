import configData from "../../config.json";
import { getCookie } from "../adminAdapter";

function onlyUnique(value: any, index: any, self: any) {
  return self.indexOf(value) === index;
}

export function getSearchData(
  setData: any,
  choseRadio: number,
  temp_splited_keywords: string[],
  temp_splited_pdbs: string[],
  limit_min: any,
  limit_max: any,
  setLoading: any
) {
  fetch(configData.SERVER_URL + `api/search/list`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-CSRFToken": getCookie(),
    },
    body: JSON.stringify({
      keywords: temp_splited_keywords.join(";"),
      and_or: encodeURIComponent(choseRadio),
      pdbs: temp_splited_pdbs.join(";"),
      limit: {
        min: limit_min,
        max: limit_max,
      },
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      setData({
        data: response.results,
        not_found: response.not_found,
        found: response.found,
      });
      setLoading(false);
    })
    .catch((error) => console.log(error.message));
}

export function getArchivedClasses(
  selectedKeys: any,
  format: any,
  setLoading: any,
  setResultLink: any
) {
  let requestVariable = [];
  for (let key of selectedKeys.keys) {
    requestVariable.push(key.toString().split("_")[1]);
  }

  setLoading(true);

  fetch(configData.SERVER_URL + `api/search/get/classes`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-CSRFToken": getCookie(),
    },
    body: JSON.stringify({
      format: format,
      classes: requestVariable.filter(onlyUnique),
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.id !== "") {
        setLoading(false);
        window.open(
          configData.SERVER_URL + "search/check/status/" + response.id,
          "_self"
        );
      }
    })
    .catch((error) => console.log(error.message));
}

export function getArchivedStructures(
  selectedKeys: any,
  format: any,
  setLoading: any,
  setResultLink: any
) {
  let requestVariable = [];
  for (let key of selectedKeys.keys) {
    requestVariable.push(key.toString().split("_")[0]);
  }
  setLoading(true);

  fetch(configData.SERVER_URL + `api/search/get/structures`, {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-CSRFToken": getCookie(),
    },
    body: JSON.stringify({
      format: format,
      structures: requestVariable.toString(),
    }),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.id !== "") {
        setLoading(false);
        window.open(
          configData.SERVER_URL + "search/check/status/" + response.id,
          "_self"
        );
      }
    })
    .catch((error) => console.log(error.message));
}

export function checkStatus(
  requestNumber: any,
  setResultSet: any,
  setLoading: any
) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-CSRFToken": getCookie(),
    },
  };
  let socket = new WebSocket(configData.SERVER_WEB_SOCKET_URL);
  let timer: any = null;
  socket.onopen = () => {
    socket.send(requestNumber);
    timer = setInterval(() => {
      socket.send(requestNumber);
    }, 5000);
  };
  socket.onmessage = (event) => {
    if (event.data === "5" || event.data === "4") {
      clearInterval(timer);
      fetch(
        configData.SERVER_URL + `api/search/check/status?id=${requestNumber}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((response) => {
          setResultSet({
            status: response.status,
            file_url: response.file_url,
          });
          setLoading(false);
        })
        .catch((error) => console.log(error.message));
    }
    if (event.data === "0") {
      setLoading(false);
    }
  };
}
