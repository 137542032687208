import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./containers/Home/Home";
import AdminPanel from "./containers/AdminPanel/AdminPanel";
import NotFound from "./containers/Errors/404";
import About from "./containers/About/About";
import Help from "./containers/Help/Help";
import Statistics from "./containers/Statistics/Statistics";
import UpdateLog from "./containers/UpdateLog/UpdateLog";
import StructureSearch from "./containers/StructureSearch/StructureSearch";
import StructureSearchResult from "./containers/StructureSearch/StructureSearchResult";
import CiteUs from "./containers/CiteUs/CiteUs";
import DownloadArchive from "./containers/DownloadArchive/DownloadArchive";

export const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/help" element={<Help />} />
        <Route path="/statistics" element={<Statistics/>} />
        <Route path="/adminpanel" element={<AdminPanel/>} />
        <Route path="/updatelog" element={<UpdateLog/>} />
        <Route path="/cite" element={<CiteUs/>} />
        <Route path="/archive" element={<DownloadArchive/>} />
        <Route path="/search" element={<StructureSearch/>} />
        <Route
          path={"/search/check/status/:requestNumber"}
          element={<StructureSearchResult/>}
        />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  );
};
