import { CloudDownloadOutlined } from "@ant-design/icons";
import { Alert, Button, Spin, Steps } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";

import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";
import { checkStatus } from "../../adapters/searchStructureAdapter";

const { Step } = Steps;

export default function StructureSearchResult() {
  let result: any = {
    status: 0,
    file_url: "",
  };
  const { requestNumber } = useParams();
  let [loading, setLoading] = useState(true);
  let [resultSet, setResultSet] = useState(result);
  useEffect(() => {
    checkStatus(requestNumber, setResultSet, setLoading);
  }, []);

  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });
  return (
    <Content style={{ padding: isDesktop ? "0 24px" : "0 0", minHeight: 280 }}>
      <div className="site-layout-content">
        <>
          <>
            <h1 style={{ marginTop: "20px" }}>
              Request number #{requestNumber}
            </h1>
            {resultSet.status === 5 ? (
              <Alert
                message="Error"
                showIcon
                description="Error"
                type="error"
                style={{ margin: "20px" }}
              />
            ) : (
              <></>
            )}
            <Steps current={resultSet.status} status="wait">
              <Step title="Make request" description="" />
              <Step title="Waiting in queue" description="" />
              <Step title="Processing" description="" />
              <Step title="Done" description="" />
            </Steps>
            {loading && resultSet.status < 4 ? (
              <div
                style={{
                  margin: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spin size="large" />
              </div>
            ) : resultSet.status === 4 ? (
              <div
                style={{
                  marginTop: "50px",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <a href={resultSet.file_url}>
                  <Button
                    type="primary"
                    size="large"
                    icon={<CloudDownloadOutlined />}
                  >
                    Download package
                  </Button>
                </a>
              </div>
            ) : (
              <></>
            )}
          </>
        </>
      </div>
    </Content>
  );
}
