import { Image, Tag, Tooltip } from "antd";
import configData from "../../../config.json";
import { search_result } from "../../../types";

export function getDesktopColumns() {
  return [
    {
      title: "Structure",
      children: [
        {
          title: "PDB ID | Model | Chain(s)",
          dataIndex: "pdb_id",
          key: "pdb_id",
          width: "200px",

          render: (text: string, row: search_result) => (
            <Tooltip
              placement="topLeft"
              title={
                "Structure title: " +
                (row.title.length === 0 ? " - " : row.title)
              }
            >
              <Tag color={"#325880"}>
                <a
                  href={"https://www.rcsb.org/structure/" + text.split("|")[0]}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {text}
                </a>
              </Tag>
            </Tooltip>
          ),
        },

        {
          title: "Molecule",
          dataIndex: "molecule",
          key: "molecule",

          filters: [
            {
              text: "Protein-RNA Complex",
              value: "CR",
            },
            {
              text: "DNA-RNA Hybrid",
              value: "HDR",
            },
            {
              text: "Solo RNA",
              value: "A",
            },
          ],
          onFilter: (value: any, record: any) =>
            record.molecule.indexOf(value) === 0,

          render: (text: any) => {
            switch (text) {
              case "CR":
                return "Protein-RNA Complex";
              case "HDR":
                return "DNA-RNA Hybrid";
              case "A":
                return "Solo RNA";
            }
          },
        },
        {
          title: "#nts",
          dataIndex: "nts",
          key: "nts",

          sorter: (a: any, b: any) => a.nts - b.nts,
          render: (nts: number) => {
            if (nts === 0) {
              return "n/a";
            } else {
              return nts;
            }
          },
        },
        {
          width: "200px",
          title: "3D structure preview",
          key: "rendered_structure",
          dataIndex: "rendered_structure",
          render: (rendered_structure: any) => {
            let file_array = rendered_structure.split("/");
            return rendered_structure.length > 0 ? (
              <>
                <a
                  href={
                    "/molstar/" +
                    file_array[file_array.length - 1].split(".")[0] +
                    "/"
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  <Tooltip
                    placement="top"
                    title={"Click to open in mol* viewer"}
                  >
                    <Image
                      preview={false}
                      src={
                        configData.SERVER_URL + `media/${rendered_structure}`
                      }
                    >
                      {" "}
                    </Image>
                  </Tooltip>
                </a>
              </>
            ) : (
              <div></div>
            );
          },
        },
      ],
    },
    {
      title: "Equivalence class",
      children: [
        {
          title: "Identifier",
          dataIndex: "equivalent",
          key: "equivalent",
          render: (text: string, row: search_result) => (
            <Tooltip
              placement="topLeft"
              title={
                "Source name: " +
                (row.source_name.length === 0 ? " - " : row.source_name)
              }
            >
              <a
                href={"http://rna.bgsu.edu/rna3dhub/nrlist/view/NR_all_" + text}
                target={"_blank"}
                rel="noreferrer"
              >
                <Tag color={"volcano"} key={text}>
                  {" "}
                  {text}
                </Tag>
              </a>
            </Tooltip>
          ),
        },
        {
          title: "Cardinality",
          dataIndex: "member_count",
          key: "member_count",
          sorter: (a: any, b: any) => a.member_count - b.member_count,
        },
        {
          title: "PDB ID | Model | Chain(s)",
          dataIndex: "representative",
          key: "representative",
          render: (text: string, row: search_result) => (
            <Tooltip
              placement="topLeft"
              title={
                "Structure title: " +
                (row.representative_source_name.length === 0
                  ? " - "
                  : row.representative_source_name)
              }
            >
              <Tag color={"#325880"}>
                {" "}
                <a
                  href={"https://www.rcsb.org/structure/" + text.split("|")[0]}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {text}
                </a>
              </Tag>
            </Tooltip>
          ),
        },
      ],
    },
  ];
}
