import { Tag } from "antd";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { InboxOutlined } from "@ant-design/icons";

export const renderClasses = (text: any) => {
  let output = [];
  for (let i in text) {
    output.push(
      <a href={"http://rna.bgsu.edu/rna3dhub/nrlist/view/NR_all_" + text[i]}>
        <Tag color={"volcano"} key={text}>
          {text[i]}
        </Tag>
      </a>
    );
  }
  if (text.length > 0) {
    return (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
        >
          <h3 style={{ margin: "0px" }}>
            <InboxOutlined /> List
          </h3>
        </AccordionSummary>
        <AccordionDetails>
          <table style={{ textAlign: "left" }}>{output}</table>
        </AccordionDetails>
      </Accordion>
    );
  } else {
    return <div></div>;
  }
};
