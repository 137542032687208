import { Layout } from "antd";
import { Content, Footer } from "antd/es/layout/layout";
import { UpdateBanner } from "../../../containers/UpdateBanner";
import { AppRouter } from "../../../router";
import { MobileNavHeader } from "./nav";

export const MobileNav = () => {
  return (
    <div className="App">
      <Layout>
        <Content style={{ padding: "0 0" }}>
          <MobileNavHeader />
          {UpdateBanner()}
          <AppRouter />
        </Content>
        <Footer style={{ textAlign: "center" }}>
          RNAsolo {new Date().getFullYear()} |{" "}
          <a href={"https://github.com/adamczykb"} style={{ color: "black" }}>
            Bartosz Adamczyk
          </a>
        </Footer>
      </Layout>
    </div>
  );
};
