import { Button, Input, Space, Table, Tag, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { JsonToCsvButton } from "../JsonToCsvButton";
import { renderClasses } from "../SharedTableElements/renderClassesList";

export function StructureAssignmentTable(
  data: any,
  loading: any,
  setState: any,
  isDesktop: any
) {
  let searchInput: any;

  let handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  let handleReset = (clearFilters: any) => {
    clearFilters();
    setState({ searchedColumn: "", searchText: "" });
  };

  let getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: any;
      selectedKeys: any;
      confirm: any;
      clearFilters: any;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput, 100);
      }
    },
  });

  const belongingnessColumn = [
    {
      title: "PDB ID",
      dataIndex: "structure",
      key: "structure",
      ...getColumnSearchProps("structure"),
      sorter: {
        compare: (a: any, b: any) => a.structure.localeCompare(b.structure),
        multiple: 1,
      },
      render: (text: any) => (
        <a
          href={"https://www.rcsb.org/structure/" + text}
          target={"_blank"}
          rel="noreferrer"
        >
          <Tag color={"#325880"}>{text}</Tag>
        </a>
      ),
    },
    {
      title: () => (
        <Tooltip title="How many classes are represented by this structure">
          Number of represented classes
        </Tooltip>
      ),

      className: "sorter-no-tooltip",
      dataIndex: "as_eq_class",
      key: "as_eq_class",
      sorter: {
        compare: (a: any, b: any) => a.as_eq_class - b.as_eq_class,
        multiple: 1,
      },
    },
    {
      title: "Represented classes",
      width: "25%",
      className: "sorter-no-tooltip",
      dataIndex: "represented_classes",
      key: "represented_classes",
      render: (text: any) => renderClasses(text),
    },
    {
      title: () => (
        <Tooltip title="How many classes does this structure belong to">
          Number of assigned classes
        </Tooltip>
      ),

      className: "sorter-no-tooltip",
      dataIndex: "as_members",
      key: "as_members",
      sorter: {
        compare: (a: any, b: any) => a.as_members - b.as_members,
        multiple: 1,
      },
    },
    {
      title: "Assigned classes",
      width: "25%",
      className: "sorter-no-tooltip",
      dataIndex: "assigned_classes",
      key: "assigned_classes",
      render: (text: any) => renderClasses(text),
    },
  ];
  return (
    <>
      <Table
        style={isDesktop ? { minWidth: "100%" } : { padding: 0 }}
        scroll={isDesktop ? { x: "auto" } : { x: "100%" }}
        showSorterTooltip={false}
        dataSource={data.table}
        loading={loading}
        columns={belongingnessColumn}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {" "}
        {JsonToCsvButton(
          data.table,
          belongingnessColumn.forEach((x) => x.title),'rnasolo-assignments-stats'
        )}
      </div>
    </>
  );
}
