import { Header } from "antd/es/layout/layout";
import mainLogo from "../../../assets/logo.png";
import { MenuOutlined } from "@ant-design/icons";
import configData from "../../../config.json";
import { useState } from "react";

export const MobileNavHeader = () => {
  const [navExpanded, setNavExpanded] = useState(false);


  function toggleNav() {
    setNavExpanded(!navExpanded);
  }
  return (
    <div style={{ transition: "all 0.5s linear" }}>
      <Header className="Header" style={{ padding: "0 0px", width: "100% " }}>
        <a href={"/"}>
          <div style={{ marginLeft: "10px", width: "155px" }}>
            <div className="logo" style={{ float: "left" }}>
              <img style={{ height: "50px" }} src={mainLogo} alt={"logo"} />
            </div>
            <div
              className="logo"
              style={{
                float: "left",
                fontSize: "25px",
                color: "white",
              }}
            >
              &nbsp;RNAsolo
            </div>
          </div>
        </a>
        <button
          onClick={() => {
            toggleNav();
          }}
          className="nav-button"
        >
          <MenuOutlined />
        </button>
      </Header>
      <div
        id={"nav-list"}
        className="NavAnimation"
        style={navExpanded ? { opacity: 1 } : { opacity: 0, height: 0 }}
      >
        <nav className="Nav">
          <a href={configData.SERVER_URL}>Home</a>
          <a href={configData.SERVER_URL + "search"}>Search the data</a>
          <a href={configData.SERVER_URL + "statistics"}>Database statistics</a>
          <a href={configData.SERVER_URL + "archive"}>Download archive</a>
          <a href={configData.SERVER_URL + "updatelog"}>Update log</a>
          <a href={configData.SERVER_URL + "about"}>About RNAsolo</a>
          <a href={configData.SERVER_URL + "cite"}>Cite us</a>
          <a href={configData.SERVER_URL + "help"}>Help</a>
        </nav>
      </div>
    </div>
  );
};
