import { Alert } from "antd";
import { useEffect, useState } from "react";
import { isBusy } from "../adapters/appAdapter";

export const UpdateBanner = () => {
  const [showBanner, setShowBanner] = useState(false);
  useEffect(() => {
    isBusy(setShowBanner);
  }, []);

  return (showBanner ? (
    <Alert
      message="RNAsolo repository is being currently updated ⏳"
      banner
      style={{ textAlign: "center" }}
      closable
    />
  ) : (
    <></>
  ));
};
