import { Button, Radio } from "antd";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getArchiveData } from "../../adapters/archiveDownloadAdapter";


  
function formatBytes(bytes:number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
  
export default function DownloadArchive() {

  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });

  let select = {
    format: "cif",
    classification: "all",
    redundancy: "representative",
    resolution: "all",
  };
  let current_archive_model={
    file:'',
    size:'0 MB',
    number_of_strucure:0
  }

  const [archiveData, setArchiveData] = useState();
  const [currentArchive, setCurrentArchive] = useState(current_archive_model);
  let [selection, setSelection] = useState(select);
  useEffect(() => {
    getArchiveData(setArchiveData,setCurrentArchive);
  }, []);
  useEffect(() => {
    applyArchiveData(archiveData,setCurrentArchive);
  }, [selection]);


  const applyArchiveData = (archiveData:any,setCurrentArchive:any) =>{

    if (archiveData && selection.resolution.replaceAll('_','.') in archiveData &&
      selection.classification in archiveData[selection.resolution.replaceAll('_','.')] &&
      selection.redundancy in archiveData[selection.resolution.replaceAll('_','.')][selection.classification] &&
      selection.format in archiveData[selection.resolution.replaceAll('_','.')][selection.classification][selection.redundancy]){
      let temp = archiveData[selection.resolution.replaceAll('_','.')][selection.classification][selection.redundancy][selection.format]
      setCurrentArchive({file:temp.file,size:temp.size,number_of_strucure:temp.structure_num})
    }else{
      setCurrentArchive({file:'',size:'0 MB',number_of_strucure:0})
    }
  }

  let renderContent = () => {
    return (
      <Content
        style={{ padding: isDesktop ? "0 24px" : "0 0", minHeight: 280 }}
      >
        <div className="site-layout-content">
          <h1>Download archive</h1>
          <h2>
            Data selected for archiving: &nbsp;
            <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
              {selection.format === "cif" && "3D (CIF)"}
              {selection.format === "fasta" && "Seq (FASTA)"}
              {selection.format === "pdb" && "3D (PDB)"}
              {selection.format === "txt" && "PDB list (TXT)"}
              &nbsp; + &nbsp;
              {selection.classification === "solo" && "solo RNAs"}
              {selection.classification === "complex" && "from protein-RNA"}
              {selection.classification === "hybrid" && "from DNA-RNA"}
              {selection.classification === "all" && "all molecules"}
              &nbsp; + &nbsp;
              {selection.redundancy === "representative" && "representatives"}
              {selection.redundancy === "member" && "all members"}
              &nbsp; + &nbsp;
              {selection.resolution === "all"
                ? "res. all"
                : "res. ≤" + selection.resolution.replace("_", ".")}
            </span>
          </h2>
          <h2>
          Selected archive volume: <i>{currentArchive.number_of_strucure} structure(s) ({formatBytes(parseInt(currentArchive.size))})</i>
          </h2>
          <br />
          <h3>data type & format</h3>
          <Radio.Group
            onChange={(e) =>
              
                setSelection({ ...selection, format: e.target.value })
            
            }
            value={selection.format}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            <Radio.Button value={"cif"}>3D structure (CIF)</Radio.Button>
            <Radio.Button value={"pdb"}>3D structure (PDB)</Radio.Button>
            <Radio.Button value={"fasta"}>sequence data (FASTA)</Radio.Button>
            <Radio.Button value={"txt"}>PDB IDs (TXT)</Radio.Button>
          </Radio.Group>
          <h3>molecule classification</h3>

          <Radio.Group
            onChange={(e) =>{
              setSelection({ ...selection, classification: e.target.value });
              applyArchiveData(archiveData,setCurrentArchive);
            }
            }
            value={selection.classification}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            <Radio.Button value={"solo"}>solo RNA molecules</Radio.Button>
            <Radio.Button value={"complex"}>
              RNAs from protein-RNA complexes
            </Radio.Button>
            <Radio.Button value={"hybrid"}>
              RNAs from DNA-RNA hybrids
            </Radio.Button>
            <Radio.Button value={"all"}>all molecules</Radio.Button>
          </Radio.Group>
          <h3>redundancy (equivalence classes)</h3>
          <Radio.Group
            onChange={(e) =>
              setSelection({ ...selection, redundancy: e.target.value })
            
            }
            value={selection.redundancy}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            <Radio.Button value={"representative"}>
              representatives
            </Radio.Button>
            <Radio.Button value={"member"}>all class members</Radio.Button>
          </Radio.Group>
          <h3>resolution [Å]</h3>
          <Radio.Group
            onChange={(e) =>
              setSelection({ ...selection, resolution: e.target.value })
            
            }
            value={selection.resolution}
            style={{ marginLeft: "20px", marginBottom: "20px" }}
          >
            <Radio.Button value={"1_5"}>≤ 1.5</Radio.Button>
            <Radio.Button value={"2_0"}>≤ 2.0</Radio.Button>
            <Radio.Button value={"2_5"}>≤ 2.5</Radio.Button>
            <Radio.Button value={"3_0"}>≤ 3.0</Radio.Button>
            <Radio.Button value={"3_5"}>≤ 3.5</Radio.Button>
            <Radio.Button value={"4_0"}>≤ 4.0</Radio.Button>
            <Radio.Button value={"20_0"}>≤ 20.0</Radio.Button>
            <Radio.Button value={"all"}>all</Radio.Button>
          </Radio.Group>
          <br />
          <a
            target="_blank"
            href={
              currentArchive.file
              
            }
            rel="noreferrer"
          >
            {currentArchive.file!==''?<Button type="primary">Download archive</Button>:<></>}
          </a>
        </div>
      </Content>
    );
  };
  return renderContent();
}
