import { Card, List, InfiniteScroll, Loading, Image } from "antd-mobile";
import Layout, { Content } from "antd/es/layout/layout";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { getRepositoryPartitionData } from "../../../adapters/homeAdapter";
import { Button, Tag } from "antd";
import configData from "../../../config.json";
import {
  repository_list,
  repository_list_class_3d_file,
  repository_list_representative_file,
} from "../../../types";

export function MobileRepositoryTable() {
  let [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [listedItems, setListedItems] = useState([]);
  const [partition, setPartition] = useState([]);
  const [fullResult, setFullResult] = useState([]);
  const [search, setSearchValue] = useState("");

  let count = 0;

  useEffect(() => {
    getRepositoryPartitionData(
      setLoading,
      setPartition,
      setListedItems,
      setFullResult
    );
  }, []);

  async function mockRequest() {
    if (count >= partition.length) {
      return [];
    }
    count++;
    return partition[count];
  }

  async function loadMore() {
    if (partition.length > 0) {
      const append = await mockRequest();
      if (append !== undefined) {
        setListedItems((val) => [...val, ...append]);
        // setHasMore(false)
        setHasMore(append.length > 0);
      }
    }
  }

  const getSourceName = (text: any) => {
    switch (text) {
      case "CR":
        return "Protein-RNA Complex";
      case "HDR":
        return "DNA-RNA Hybrid";
      case "A":
        return "Solo RNA";
      default:
        return "No data";
    }
  };

  const downloadButtonRep = (
    item: repository_list_representative_file,
    text: any,
    class_file_structure:any
  ) => {
    let cif_file = item.cif_file;
    let pdb_file = item.pdb_file;
    return (
      <div style={{ textAlign: "center" }}>
        <a
          href={configData.SERVER_URL + `media/${cif_file}`}
          rel="noreferrer"
          target="_blank"
        >
          <Button
            type="primary"
            style={{ margin: "10px", width: "90%", textOverflow: "…" }}
            ghost
          >
            {text} CIF
          </Button>
        </a>
        {pdb_file.length > 0 ? (
          <a
            href={configData.SERVER_URL + `media/${pdb_file}`}
            rel="noreferrer"
            target="_blank"
          >
            <Button
              type="primary"
              style={{ margin: "10px", width: "90%", textOverflow: "…" }}
              ghost
            >
              {text} PDB
            </Button>
          </a>
        ) : (
          ""
        )}
        {class_file_structure.representative ? (
          <a
            href={
              configData.SERVER_URL + `media/${class_file_structure.representative}`
            }
            rel="noreferrer"
            target="_blank"
          >
            <Button
              type="primary"
              style={{ margin: "10px", width: "90%", textOverflow: "…" }}
              ghost
            >
              {text} FASTA
            </Button>
          </a>
        ) : (
          ""
        )}
      </div>
    );
  };

  const downloadButtonMembers = (
    item: repository_list_class_3d_file,
    text: any,
    equivalent: string,
    class_file_structure:any
  ) => {
    return (
      <div style={{ textAlign: "center" }}>
        <a
          href={
            configData.SERVER_URL +
            "files/member/archive/cif/" +
            equivalent +
            "/"
          }
          rel="noreferrer"
          target="_blank"
        >
          <Button
            type="primary"
            style={{ margin: "10px", width: "90%", textOverflow: "…" }}
            ghost
          >
            {text} CIF
          </Button>
        </a>
        {item.pdb_file ? (
          <a
            href={
              configData.SERVER_URL +
              "files/member/archive/pdb/" +
              equivalent +
              "/"
            }
            rel="noreferrer"
            target="_blank"
          >
            <Button
              type="primary"
              style={{ margin: "10px", width: "90%", textOverflow: "…" }}
              ghost
            >
              {text} PDB
            </Button>
          </a>
        ) : (
          ""
        )}
        {class_file_structure.all_members ? (
          <a
          href={
            configData.SERVER_URL +
            "files/member/archive/fasta/" +
            equivalent +
            "/"
          }
          rel="noreferrer"
          target="_blank"
        >
          <Button
            type="primary"
            style={{ margin: "10px", width: "90%", textOverflow: "…" }}
            ghost
          >
            All class members FASTA
          </Button>
        </a>
        ) : (
          ""
        )}
      </div>
    );
  };
  const onSearch = (value: any) => {
    setSearchValue(value.toString().toUpperCase());
  };
  const InfiniteScrollContent = ({ hasMore }: { hasMore?: boolean }) => {
    return (
      <>
        {hasMore ? (
          <>
            <span>Loading</span>
            <Loading />
          </>
        ) : (
          <span>--- No more ---</span>
        )}
      </>
    );
  };
  return (
    <Layout className="site-layout-background" style={{ padding: "0px 0" }}>
      <Content>
        <Search
          maxLength={60}
          size="large"
          placeholder="Search equivalence class or PDB ID"
          id={"search_pdb"}
          allowClear
          onSearch={onSearch}
          enterButton
        />
        {loading ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <Loading />
          </div>
        ) : (
          <>
            <List>
              {search.length > 0
                ? fullResult.map((item: repository_list, index: any) => {
                    if (
                      item.eq_class.indexOf(search) > -1 ||
                      item.representative.indexOf(search) > -1
                    ) {
                      return (
                        <List.Item key={index}>
                          <Card>
                            <strong>Equivalence class:</strong>
                            <br />
                            Identifier:{" "}
                            <a
                              href={
                                "https://www.rcsb.org/structure/" +
                                item.eq_class
                              }
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              <Tag color={"volcano"}>{item.eq_class}</Tag>
                            </a>
                            <br />
                            Cardinality: {item.members}
                            <br />
                            <br />
                            <strong>Representative:</strong> <br />
                            <div style={{ height: "auto", overflow: "auto" }}>
                              <div style={{ width: "50%", float: "left" }}>
                                PDB:{" "}
                                <Tag color={"#325880"}>
                                  <a
                                    href={
                                      "https://www.rcsb.org/structure/" +
                                      item.representative
                                    }
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {item.representative}
                                  </a>
                                </Tag>
                                <br />
                                Model no.: {item.model}
                                <br />
                                Chain(s): {item.chains}
                                <br />
                                Molecule: {getSourceName(item.molecule)}
                                <br />
                                #nts: {item.nts === '' ? "n/a" : item.nts}
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  float: "left",
                                  textAlign: "center",
                                }}
                              >
                                <Image
                                  src={
                                    configData.SERVER_URL +
                                    `media/${item.rendered_structure}`
                                  }
                                  width={"100%"}
                                  height={"100%"}
                                  fit="contain"
                                />
                              </div>
                            </div>
                            <br />
                            <div style={{ height: "auto", overflow: "auto" }}>
                              <div style={{ width: "50%", float: "left" }}>
                                {downloadButtonRep(
                                  item.representative_file,
                                  "Representative",
                                  item.class_file_structure
                                )}
                              </div>
                              <div style={{ width: "50%", float: "left" }}>
                                {downloadButtonMembers(
                                  item.class_file_3d,
                                  "All class member",
                                  item.eq_class,
                                  item.class_file_structure
                                )}
                              </div>
                            </div>
                          </Card>
                        </List.Item>
                      );
                    } else {
                      return "";
                    }
                  })
                : listedItems.map((item: any, index: any) => {
                    return (
                      <List.Item key={index}>
                        <Card>
                          <strong>Equivalence class:</strong>
                          <br />
                          Identifier:{" "}
                          <a
                            href={
                              "https://www.rcsb.org/structure/" + item.eq_class
                            }
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            <Tag color={"volcano"}>{item.eq_class}</Tag>
                          </a>
                          <br />
                          Cardinality: {item.members}
                          <br />
                          <br />
                          <strong>Representative:</strong> <br />
                          <div style={{ height: "auto", overflow: "auto" }}>
                            <div style={{ width: "50%", float: "left" }}>
                              PDB:{" "}
                              <Tag color={"#325880"}>
                                <a
                                  href={
                                    "https://www.rcsb.org/structure/" +
                                    item.representative
                                  }
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {item.representative}
                                </a>
                              </Tag>
                              <br />
                              Model no.: {item.model}
                              <br />
                              Chain(s): {item.chains}
                              <br />
                              Molecule: {getSourceName(item.molecule)}
                              <br />
                              #nts: {item.nts === 0 ? "n/a" : item.nts}
                            </div>
                            <div
                              style={{
                                width: "50%",
                                float: "left",
                                textAlign: "center",
                              }}
                            >
                              <Image
                                src={
                                  configData.SERVER_URL +
                                  `media/${item.rendered_structure}`
                                }
                                width={"100%"}
                                height={"100%"}
                                fit="contain"
                              />
                            </div>
                          </div>
                          <br />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              flexWrap: "wrap",
                              height: "auto",
                              overflow: "auto",
                            }}
                          >
                            <div style={{ width: "210px" }}>
                              {downloadButtonRep(
                                item.representative_file,
                                "Representative",
                                item.class_file_structure
                              )}
                            </div>
                            <div style={{ width: "210px" }}>
                              {downloadButtonMembers(
                                item.class_file_3d,
                                "All class member",
                                item.eq_class,
                                item.class_file_structure
                              )}
                            </div>
                          </div>
                        </Card>
                      </List.Item>
                    );
                  })}
            </List>
            {search.length > 0 ? (
              ""
            ) : (
              <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
                <InfiniteScrollContent hasMore={hasMore} />
              </InfiniteScroll>
            )}
          </>
        )}

        {/*<Table pagination={{position: ['bottomCenter']}} style={{padding: 0}} scroll={{y: 500, x: 1000}}*/}
        {/*       columns={columns}*/}
        {/*       dataSource={data.data}*/}
        {/*       loading={loading}/>*/}
      </Content>
    </Layout>
  );
}
