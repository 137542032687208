
import configData from "../../config.json";

export const getArchiveData = (setArchiveData: any,setCurrentArchive:any) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    fetch(configData.SERVER_URL + "api/archives/data/", requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setArchiveData(response.results);
        if (response.results.all.all.representative.cif){
            let temp = response.results.all.all.representative.cif
            setCurrentArchive({file:temp.file,size:temp.size,number_of_strucure:temp.structure_num})
        }
        
      })
      .catch((error) => console.log(error.message));
  };