import "../../styles/AdminPanel/AdminPanel.css";
import { Content } from "antd/es/layout/layout";
import { Alert } from "antd";
import configData from "../../config.json";
import React, { useEffect, useState } from "react";
import { LoginPane } from "../../components/adminComponents/LoginPane";
import { checkIfLogged, getCookie } from "../../adapters/adminAdapter";
import { Dashboard } from "../../components/adminComponents/Dashboard";
import { LayoutType } from "recharts/types/util/types";

export default function AdminPanel() {
  let confs = {
    pdb_update_minute: 0,
    pdb_update_hour: 0,
    pdb_update_day_of_week: 0,
    bgsu_update_minute: 0,
    bgsu_update_hour: 0,
    bgsu_update_day_of_week: 0,
    set: true,
    log: [],
  };
  let alertData = {
    show: false,
    text: "",
    isError: false,
    lockButtons: false,
  };
  const [alert, setAlert] = useState(alertData);
  const [formLayout] = useState<LayoutType>("horizontal");
  let [userLogged, setUserLogged] = useState(false);
  let [isError, setIsError] = useState(false);
  let [configs, setConfigs] = useState(confs);
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 },
        }
      : null;

  const onFinish = (values: any) => {
    fetch(configData.SERVER_URL + "api/admin/login/", {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "X-CSRFToken": getCookie(),
      },
      body: JSON.stringify({
        username: values.username,
        password: values.password,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setIsError(response.success !== "yes");
        setUserLogged(response.success === "yes");
      })
      .catch((error) => console.log(error.message));
  };
  useEffect(() => {
    checkIfLogged(setUserLogged, setConfigs);
  }, [userLogged]);

  return (
    <Content style={{ padding: "0 24px", minHeight: 280 }}>
      <div className="site-layout-content" style={{ whiteSpace: "pre-line" }}>
        {isError ? (
          <Alert message="Wrong login or password" type="error" showIcon />
        ) : null}
        {userLogged
          ? Dashboard(
              configs,
              setConfigs,
              formItemLayout,
              formLayout,
              alert,
              setAlert
            )
          : LoginPane(onFinish)}
      </div>
    </Content>
  );
}
