import { Table } from "antd";

const { Column, ColumnGroup } = Table;

export function VersionTable(data: any, loading: any, isDesktop: any) {
  return (
    <Table
      dataSource={data.all}
      style={isDesktop ? { padding: "auto" } : { padding: 0 }}
      scroll={isDesktop ? { x: "auto" } : { x: "100%" }}
      loading={loading}
      id={"version-table"}
    >
      <Column title="Version" dataIndex="version" key="version" />
      <Column title="Update date" dataIndex="timestamp" key="timestamp" />
      <ColumnGroup title="Equivalence class">
        <Column
          title="Total number"
          dataIndex={"eq_class_count"}
          key="eq_class_count"
          sorter={{
            compare: (a: any, b: any) => a.eq_class_count - b.eq_class_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_eq"
          key="growth_eq"
          sorter={{
            compare: (a: any, b: any) => a.growth_eq - b.growth_eq,
            multiple: 1,
          }}
        />
      </ColumnGroup>
      <ColumnGroup title="Class member">
        <Column
          title="Total number"
          dataIndex={"member_count"}
          key="member_count"
          sorter={{
            compare: (a: any, b: any) => a.member_count - b.member_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_member"
          key="growth_member"
          sorter={{
            compare: (a: any, b: any) => a.growth_member - b.growth_member,
            multiple: 1,
          }}
        />
      </ColumnGroup>
      <ColumnGroup title="Protein-RNA Complex">
        <Column
          title="Total number"
          dataIndex={"complex_molecule_count"}
          key="complex_molecule_count"
          sorter={{
            compare: (a: any, b: any) =>
              a.complex_molecule_count - b.complex_molecule_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_complex_molecule"
          key="growth_complex_molecule"
          sorter={{
            compare: (a: any, b: any) =>
              a.growth_complex_molecule - b.growth_complex_molecule,
            multiple: 1,
          }}
        />
      </ColumnGroup>
      <ColumnGroup title="DNA-RNA Hybrid">
        <Column
          title="Total number"
          dataIndex={"hybrid_molecule_count"}
          key="hybrid_molecule_count"
          sorter={{
            compare: (a: any, b: any) =>
              a.hybrid_molecule_count - b.hybrid_molecule_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_hybrid_molecule"
          key="growth_hybrid_molecule"
          sorter={{
            compare: (a: any, b: any) =>
              a.growth_hybrid_molecule - b.growth_hybrid_molecule,
            multiple: 1,
          }}
        />
      </ColumnGroup>
      <ColumnGroup title="Solo RNA">
        <Column
          title="Total number"
          dataIndex={"solo_molecule_count"}
          key="solo_molecule_count"
          sorter={{
            compare: (a: any, b: any) =>
              a.solo_molecule_count - b.solo_molecule_count,
            multiple: 1,
          }}
        />
        <Column
          title="Growth"
          dataIndex="growth_solo_molecule"
          key="growth_solo_molecule"
          sorter={{
            compare: (a: any, b: any) =>
              a.growth_solo_molecule - b.growth_solo_molecule,
            multiple: 1,
          }}
        />
      </ColumnGroup>
    </Table>
  );
}
