import { Button } from "antd";
import { Parser } from "json2csv";
import { DownloadOutlined } from "@ant-design/icons";

export function JsonToCsvButton(data: any, fields: any,file_name:string) {
  function parseToCSV() {
    const opts = { fields: fields };
    const parser = new Parser(opts);
    for (let i = 0; i < data.length; i++) {
      if (data[i].molecule === "A") data[i].molecule = "Solo RNA";
      if (data[i].molecule === "HDR") data[i].molecule = "DNA-RNA Hybrid";
      if (data[i].molecule === "CR") data[i].molecule = "Protein-RNA Complex";
    }
    let csv = parser.parse(data);
    const element = document.createElement("a");
    if(file_name==='rnasolo-assignments-stats'){
      csv=csv.replace('structure','PDBID').replace('as_eq_class','as_representative').replace('as_members','as_member')
    }
    if(file_name==='rnasolo-monthly-stats' || file_name==='rnasolo-version-stats'){
      csv=csv.replace('timestamp','date').replace('version','bgsu_version').replace('eq_class_count','eq_classes')
      .replace('member_count','members').replace('growth_eq','eq_growth').replace('growth_member','member_growth').replace('complex_molecule_count','complexes')
      .replace('growth_complex_molecule','complex_growth').replace('hybrid_molecule_count','hybrids')
      .replace('growth_hybrid_molecule','hybrid_growth').replace('solo_molecule_count','solo').replace('growth_solo_molecule','solo_growth')
    }
    
    const file = new Blob(
      [csv.replaceAll('"', "").replaceAll("[", '"[').replaceAll("]", ']"').replaceAll('stats.','')],
      { type: "text/plain" }
    );
    element.href = URL.createObjectURL(file);
    element.download = file_name+".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }

  return (
    <Button style={{ margin: "20px" }} onClick={parseToCSV}>
      <DownloadOutlined />
      Download CSV
    </Button>
  );
}
