import { Content } from "antd/es/layout/layout";
import { Divider, Image, Typography } from "antd";
import "../../styles/Help/Help.css";
import safari from "../../assets/help/234px-Safari_browser_logo.png";
import firefox from "../../assets/help/640px-Firefox_logo.png";
import chrome from "../../assets/help/Google_Chrome_icon_(September_2014).png";
import edge from "../../assets/help/microsoftedgenewlogo.jpg";
import help_1 from "../../assets/help/help_1.png";
import search_1 from "../../assets/help/search_1.png";
import search_2 from "../../assets/help/search_2.png";
import archive_1 from "../../assets/help/archive_1.png";
import help_3 from "../../assets/help/help_3.png";
import workflow from "../../assets/help/workflow.svg";
import { useMediaQuery } from "react-responsive";

export default function Help() {
  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });

  return (
    <Content
      style={{ padding: isDesktop ? "0 24px" : "0 0", minHeight: 280 }}
      id={"help"}
    >
      <div className="site-layout-content" >
        <h1>Help</h1>
        <h2>Table of contents</h2>
        <ol>
          <li>
            <a
              href={"#rnasolo_workflow"}
              onClick={() => {
                // @ts-ignore
                document.getElementById("RNAsolo_workflow").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              RNAsolo workflow
            </a>
          </li>
          <li>
            <a
              href={"#nav"}
              onClick={() => {
                // @ts-ignore
                document.getElementById("RNAsolo_navigation").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              Web application
            </a>
            <ol style={{ paddingLeft: 10 }}>
              <li>
                <a
                  href={"#nav1"}
                  onClick={() => {
                    // @ts-ignore
                    document
                      .getElementById("RNAsolo_navigation_1")
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      });
                  }}
                >
                  Home page
                </a>
              </li>
              <li>
                <a
                  href={"#nav2"}
                  onClick={() => {
                    // @ts-ignore
                    document
                      .getElementById("RNAsolo_navigation_2")
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      });
                  }}
                >
                  Search the data
                </a>
              </li>
              <li>
                <a
                  href={"#nav3"}
                  onClick={() => {
                    // @ts-ignore
                    document
                      .getElementById("RNAsolo_navigation_3")
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      });
                  }}
                >
                  Database statistics
                </a>
              </li>
              <li>
                <a
                  href={"#nav4"}
                  onClick={() => {
                    // @ts-ignore
                    document
                      .getElementById("RNAsolo_navigation_4")
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      });
                  }}
                >
                  Download archive
                </a>
              </li>
              <li>
                <a
                  href={"#nav5"}
                  onClick={() => {
                    // @ts-ignore
                    document
                      .getElementById("RNAsolo_navigation_5")
                      .scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      });
                  }}
                >
                  Update log
                </a>
              </li>
            </ol>
          </li>
          <li>
            <a
              href={"#api"}
              onClick={() => {
                // @ts-ignore
                document.getElementById("RNAsolo_API").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              RNAsolo API
            </a>
          </li>
          <li>
            <a
              href={"#requirements"}
              onClick={() => {
                // @ts-ignore
                document.getElementById("System_requirements").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              System requirements
            </a>
          </li>
          <li>
            <a
              href={"#funding"}
              onClick={() => {
                // @ts-ignore
                document.getElementById("Funding").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              Funding
            </a>
          </li>
          <li>
            <a
              href={"#references"}
              onClick={() => {
                // @ts-ignore
                document.getElementById("References").scrollIntoView({
                  behavior: "smooth",
                  block: "start",
                  inline: "nearest",
                });
              }}
            >
              References
            </a>
          </li>
        </ol>
        <Divider />
        <h1 style={{ color: "#005675" }} id={"RNAsolo_workflow"}>
          1. RNAsolo workflow
        </h1>
        <h2 style={{ color: "#005675" }}>Workflow scheme</h2>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image alt={"infographic"} src={workflow} width={"70%"} />
        </div>
        <br />
        <Divider />
        <h1 style={{ color: "#005675" }} id={"RNAsolo_navigation"}>
          2. Web application
        </h1>
        <p>
          The web application provides a convenient and user-friendly interface
          to the RNAsolo system. It allows browsing the database contents (table
          on <i>Home page</i>), searching for structures in the database (search
          in individual columns of the <i>Home page</i> table, search engine
          available from <i>Search the data</i>), creating own data subsets to
          download, downloading benchmark sets prepared by the system (available
          from <i>Download archive</i>), browsing and downloading data
          statistics generated by the system (available from
          <i>Database statistics</i>). The following sections of Help briefly
          introduce various options of the application.
        </p>
        <h2 style={{ color: "#005675" }} id={"RNAsolo_navigation_1"}>
          2.1 Home page
        </h2>
        <p>
          From the Homepage, users can browse the database contents. This page
          shows a table of equivalence classes consistent with the current
          version of the BGSU Representative Sets of RNA 3D Structures (the
          version id is displayed in the sidebar below the menu). The table
          contains the following data about each equivalence class:
        </p>
        <ul>
          <li>class identifier,</li>
          <li>class size (the number of structures contained in it),</li>
          <li>PDB ID of the class representative,</li>
          <li>model and chain identifiers of the representative,</li>
          <li>
            the type of the molecule from which the representative derives (solo
            RNA, protein-RNA complex, DNA-RNA hybrid),
          </li>
          <li>the size of the representative (number of nucleotides),</li>
          <li>visualized 3D structure of the representative.</li>
        </ul>
        <p>
          Hovering over the class id results in a popup window listing the
          organisms (source names) from which the class members derive. Clicking
          on this id takes users to the class page on the BGSU RNA Site.
          Hovering over the representative PDB ID displays a popup window with
          the source title. Clicking on this identifier directs the user to the
          structure page in the Protein Data Bank. Clicking on 3D structure
          preview opens a Mol* program window with the structure visualization.
          One can use all the program options available there, like changing the
          model, coloring, hiding and displaying structure elements, animating
          the structure, saving the file with the structure view or animation,
          etc.
        </p>
        <p>
          The buttons in the rightmost columns of the table allow downloading:
        </p>
        <ul>
          <li>FASTA file with the sequence of the class representative,</li>
          <li>
            ZIP file containing the sequences of all class members in FASTA
            files,
          </li>
          <li>
            PDB or CIF file with the 3D structure of the class representative,
          </li>
          <li>
            ZIP file containing 3D structures of all class members in PDB or CIF
            files.
          </li>
        </ul>
        <p>
          By default, the table is sorted lexicographically by class ID (i.e.,
          the first column of the table). Users can also sort it descending or
          ascending by class cardinality, representative model number,
          representative chain identifier, and representative size. Sorting runs
          by clicking buttons located in the column headers. The table allows
          searching for the class identifier or PDB identifier of a class
          representative. To run the search, users should click on the
          magnifying glass next to the header of a given column.
        </p>
        <p>
          The table can be filtered by the molecule type. By clicking the button
          next to the <i>Molecule header</i>, users can select the type of
          interest.
        </p>
        <p>
          The <i>Show/Hide columns</i> button allows hiding selected table
          columns or showing them again. The
          <i>Download CSV</i> button enables downloading a CSV file that
          contains tabular data (those from the currently visible table
          columns).
        </p>
        <p>
          The table is displayed divided into pages. By default, 10 table rows
          per page are shown. Users can set other pagination by selecting 20,
          50, or 100 rows per page (option available at the bottom of the page,
          under the table). All user actions (sorting, searching, saving to
          file) apply to the entire table, not just the currently displayed
          rows.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image alt={"infographic"} width={"75%"} src={help_1} />
        </div>
        <br />
        <Divider />
        <h2 style={{ color: "#005675" }} id={"RNAsolo_navigation_2"}>
          2.2 Search the data
        </h2>
        <p>
          Here, users can run a database search by PDB identifiers or keywords
          and structure size. The database query is defined via one of two tabs:
        </p>
        <h3 style={{ color: "#005675" }}>Search structures by PDB IDs</h3>
        <p>
          The <i>PDB IDs</i> tab contains a single edit box into which users can
          enter 1-10 PDB IDs of the structures to search. Each identifier should
          be 4 characters long. Identifiers are semicolon-separated. The search
          engine returns a list of all entries with IDs matching the query. In
          RNAsolo, chains of multi- chain PDB structures are treated separately,
          like in the BGSU Representative Sets of RNA 3D Structures.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image alt={"infographic"} width={"75%"} src={search_1} />
        </div>
        <br />
        <h3 style={{ color: "#005675" }}>
          Search structures by source name, structure title or size.
        </h3>
        <p>
          The Keywords & size tab allows defining a query consisting of one or
          two elements. Users can search for structures that contain a string of
          interest in the title or the source attribute (c.f. PDB/mmCIF file).
          The edit box allows entering 1-5 keywords separated by semicolons.
          Each keyword can be 3- 300 characters long. The search engine returns
          all structures matching at least one of the keywords.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image alt={"infographic"} width={"75%"} src={search_2} />
        </div>
        <br />
        <p>
          Users can also search for structures whose size meets the given
          condition. To do this, they click the bottom checkbox and define the
          condition. If only the min value is given, the engine looks for
          structures larger than min. If only max value is given, the engine
          looks for structures smaller than max. If both values are given, we
          find structures whose size is in the min-max range (c.f., figure
          below). If min=max, we look for structures whose size is equal to
          min/max.
        </p>
        <p>
          One can also construct a query with both elements, keywords, and size.
          To create such a query, users must check both checkboxes, define the
          conditions (provide keywords and min/max values) and specify the
          conjunction (AND/OR). If AND is selected, the search engine looks for
          structures that satisfy both conditions at the same time (have the
          given size and are associated with the given keyword). If OR is
          selected, the search engine finds structures that satisfy at least one
          condition.
        </p>
        <p>
          Defaults are associated with keyword search (only top check box
          clicked).
        </p>
        <Divider />
        <h2 style={{ color: "#005675" }} id={"RNAsolo_navigation_3"}>
          2.3 Database statistics
        </h2>
        <p>
          The statistics page shows 6 graphs and tables with the numerical data
          associated with the graphs. Tabular data can be saved to a CSV file.
          The following statistics are available:
        </p>
        <ul>
          <li>
            total number of RNA structures in the database by source (solo RNA,
            RNAs from protein-RNA complexes, RNAs from DNA-RNA hybrids),
          </li>
          <li>
            monthly growth of structures in the database by source (solo RNA,
            RNAs from protein-RNA complexes, RNAs from DNA-RNA hybrids),
          </li>
          <li>total number of equivalence classes in the database,</li>
          <li>monthly growth of equivalence classes in the database,</li>
          <li>
            structures with their assignments (for each structure, the system
            calculates how many equivalence classes it belongs to and how many
            classes it represents, these data are aggregated, size of the bead
            shows how many structures belong to X classes and represent Y
            classes),
          </li>
          <li>number of classes with given cardinality.</li>
        </ul>
        <Divider />
        <h2 style={{ color: "#005675" }} id={"RNAsolo_navigation_4"}>
          2.4 Download archive
        </h2>
        <p>
          During each database update, the RNAsolo system prepares 320 benchmark
          datasets. They are available for download through the Download archive
          page. The content of each archive is different, defined by 4 elements:
        </p>
        <ul>
          <li>
            data type & format (3D structures in CIF format, 3D structures in
            PDB format, sequences in FASTA format, PDB identifiers listed in TXT
            file),
          </li>
          <li>
            molecule classification (solo RNA molecules, RNAs from protein-RNA
            complexes, RNAs from DNA- RNA hybrids, all molecules),
          </li>
          <li>
            redundancy (equivalence class representatives, all class members),
          </li>
          <li>
            resolution of structures (≤1.5, ≤2.0, ≤2.5, ≤3.0, ≤3.5, ≤4.0, ≤20.0,
            all).
          </li>
        </ul>
        <p>
          To select a dataset for download, users should mark which data they
          want in the dataset. The information in the upper line shows the
          selection and brief data on the content - the number of structures in
          the dataset and the file size.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image alt={"infographic"} width={"75%"} src={archive_1} />
        </div>
        <br />
        <Divider />
        <h2 style={{ color: "#005675" }} id={"RNAsolo_navigation_5"}>
          2.5 Update log
        </h2>
        <p>This page provides detailed information about repository updates.</p>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image alt={"infographic"} width={"75%"} src={help_3} />
        </div>
        <br />
        <Divider />
        <h1 style={{ color: "#005675" }} id={"RNAsolo_API"}>
          3. RNAsolo API
        </h1>
        <p>RNAsolo provides three public API services.</p>
        <h2>1. Get cleaned structure</h2>
        <Typography>
          <pre style={{ fontFamily: "inherit" }}>
            <br />
            <span style={{ color: "#E2475D" }}>
              {"https://rnasolo.cs.put.poznan.pl/api/query/structure?"}
            </span>
            {"pdbid="}
            <span style={{ color: "#2B6F78" }}>{"<PDB_ID>"}</span>
            {"&format="}
            <span style={{ color: "#2B6F78" }}>{"<format>"}</span>
            {"&chains="}
            <span style={{ color: "#2B6F78" }}>{"<chains>"}</span>
            {"&models="}
            <span style={{ color: "#2B6F78" }}>{"<models>"}</span>
            <br />
            &nbsp;
          </pre>
        </Typography>
        <br />
        <span style={{ fontWeight: "bold" }}>{"<PDB_ID>"}</span>{" "}
        {"PDB ID structure that is looked for"}
        <br />
        <span style={{ fontWeight: "bold" }}>{"<chains>"}</span>{" "}
        {"Structure chains separated by comma (,)"}
        <br />
        <span style={{ fontWeight: "bold" }}>{"<format>"}</span>{" "}
        <span style={{ fontStyle: "italic" }}>
          {"(optional, default mmCIF)"}
        </span>
        {" Structure file format that will be returned ex. PDB, mmCIF, FASTA"}
        <br />
        <span style={{ fontWeight: "bold" }}>{"<models>"}</span>{" "}
        <span style={{ fontStyle: "italic" }}>{"(optional, default all)"}</span>
        {" Structure model id or ids separated by comma (,)"}
        <br />
        <br />
        <h3>Example</h3>
        <a
          href={"/api/query/structure?pdbid=6VFF&format=CIF&chains=D,C&model=1"}
        >
          https://rnasolo.cs.put.poznan.pl/api/query/structure?pdbid=6VFF&format=CIF&chains=D,C&model=1
        </a>
        <br />
        <br />
        <h2>2. Get equivalence class</h2>
        <Typography>
          <pre style={{ fontFamily: "inherit" }}>
            <br />
            <span style={{ color: "#E2475D" }}>
              {"https://rnasolo.cs.put.poznan.pl/api/query/class?"}
            </span>
            {"identifier="}
            <span style={{ color: "#2B6F78" }}>{"<identifier>"}</span>
            {"&format="}
            <span style={{ color: "#2B6F78" }}>{"<format>"}</span>
            <br />
            &nbsp;
          </pre>
        </Typography>
        <br />
        <span style={{ fontWeight: "bold" }}>{"<identifier>"}</span>{" "}
        {"Class identifier that is looked for"}
        <br />
        <span style={{ fontWeight: "bold" }}>{"<format>"}</span>{" "}
        <span style={{ fontStyle: "italic" }}>
          {"(optional, default mmCIF)"}
        </span>
        {
          " Archived class will contain structure files in format ex. PDB, mmCIF, FASTA"
        }
        <br />
        <br />
        <h3>Example</h3>
        <a href={"/api/query/class?identifier=02656&format=CIF"}>
          https://rnasolo.cs.put.poznan.pl/api/query/class?identifier=02656&format=CIF
        </a>
        <br />
        <br />
        <h2>3. Get cleaned structure with every used chain in the database</h2>
        <Typography>
          <pre style={{ fontFamily: "inherit" }}>
            <br />
            <span style={{ color: "#E2475D" }}>
              {"https://rnasolo.cs.put.poznan.pl/api/query/structure/package?"}
            </span>
            {"pdbid="}
            <span style={{ color: "#2B6F78" }}>{"<PDB_ID>"}</span>
            {"&format="}
            <span style={{ color: "#2B6F78" }}>{"<format>"}</span>
            {"&models="}
            <span style={{ color: "#2B6F78" }}>{"<models>"}</span>
            <br />
            &nbsp;
          </pre>
        </Typography>
        <br />
        <span style={{ fontWeight: "bold" }}>{"<PDB_ID>"}</span>{" "}
        {"PDB ID structure that is looked for"}
        <br />
        <span style={{ fontWeight: "bold" }}>{"<format>"}</span>{" "}
        <span style={{ fontStyle: "italic" }}>
          {"(optional, default mmCIF)"}
        </span>{" "}
        {" Structure file format that will be returned ex. PDB, mmCIF, FASTA"}
        <br />
        <span style={{ fontWeight: "bold" }}>{"<models>"}</span>{" "}
        <span style={{ fontStyle: "italic" }}>{"(optional, default all)"}</span>{" "}
        {" Structure model id or ids separated by comma (,)"}
        <br />
        <br />
        <h3>Example</h3>
        <a href={"/api/query/structure/package?pdbid=430D&format=CIF&models=1"}>
          https://rnasolo.cs.put.poznan.pl/api/query/structure/package?pdbid=430D&format=CIF&models=1
        </a>
        <br />
        <br />
        <Divider />
        <h1 style={{ color: "#005675" }} id={"System_requirements"}>
          4. System requirements
        </h1>
        <p>
          RNAsolo is designed to work with most of web browsers. The latest
          versions of browsers are recommended.
        </p>
        <table style={{ border: "1px solid black", textAlign: "center" }}>
          <tr>
            <td width="75px">
              <a href="https://www.google.com/intl/en_en/chrome/">
                <img alt={"chrome logo"} width="55px" src={chrome} />
              </a>
            </td>
            <td width="75px">
              <a href="https://www.microsoft.com/en-us/edge">
                <img alt={"edge logo"} width="64px" src={edge} />
              </a>
            </td>
            <td width="75px">
              <a href="https://www.mozilla.org/en-US/firefox/new/">
                <img alt={"firefox logo"} width="64px" src={firefox} />
              </a>
            </td>
            <td width="75px">
              <a href="https://www.apple.com/safari/">
                <img alt={"safari logo"} width="64px" src={safari} />
              </a>
            </td>
          </tr>
          <tr style={{ height: "50px" }}>
            <td>
              <b>
                Chrome
                <br />
                85
              </b>
            </td>
            <td>
              <b>
                Edge
                <br />
                88
              </b>
            </td>
            <td>
              <b>
                Firefox
                <br />
                86
              </b>
            </td>
            <td>
              <b>
                Safari
                <br />
                14
              </b>
            </td>
          </tr>
        </table>
        <Divider />
        <h1 style={{ color: "#005675" }} id={"Funding"}>
          5. Funding
        </h1>
        <p>
          We acknowledge support from the National Science Centre, Poland
          [2019/35/B/ST6/03074], the statutory funds of Poznan University of
          Technology and Institute of Bioorganic Chemistry PAS.
        </p>
        <Divider />
        <h1 style={{ color: "#005675" }} id={"References"}>
          6. References
        </h1>
        <p>
          1. N.B. Leontis, C.L. Zirbel (2012) Nonredundant 3D Structure Datasets
          for RNA Knowledge Extraction and Benchmarking. In: RNA 3D Structure
          Analysis and Prediction (Eds: N. Leontis & E. Westhof) 27, 281-298.
          Springer Berlin Heidelberg.
        </p>
        <p>
          2. P.J. Cock, T. Antao, J.T. Chang, B.A. Chapman, C.J. Cox, et. al.
          (2009) Biopython: freely available Python tools for computational
          molecular biology and bioinformatics. Bioinformatics 25(11),
          1422-1423.
        </p>
        <p>
          3. D. Sehnal, S. Bittrich, M. Deshpande, R. Svobodova, K. Berka, V.
          Bazgier, S. Velankar, S. Burley, J. Koca, A. Rose (2021) Mol* Viewer:
          modern web app for 3D visualization and analysis of large biomolecular
          structures. Nucleic Acids Res 49(W1), W431-W437.
        </p>
      </div>
    </Content>
  );
}
