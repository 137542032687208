import { useEffect, useState } from "react";
import { Button, Dropdown, Input, Space, Table } from "antd";
import { DownOutlined, SearchOutlined, TableOutlined } from "@ant-design/icons";
import { getDesktopColumns, getDropdownList } from "./desktopColumns";
import { JsonToCsvButton } from "../../statisticsComponents/JsonToCsvButton";
import { getRepositoryData } from "../../../adapters/homeAdapter";
import { column_hider, repository_list } from "../../../types";
export function DesktopRepositoryTable() {
  let result_array: repository_list[] = [];

  let [loading, setLoading] = useState(true);
  const [data, setData] = useState(result_array);

  let searchState = {
    searchText: "",
    searchedColumn: "",
  };

  let searchInput: any;
  let currentData: any;
  let columnState: column_hider = {
    top_classes: true,
    top_representative: true,
    top_download: true,
    top_download_sequence: true,
    equivalent: true,
    member_count: true,
    rendered_structure: true,
    representative: true,
    model: true,
    chains: true,
    molecule: true,
    residues: true,
    representative_file_3d: true,
    class_file_3d: true,
    representative_file_seq: true,
    class_file_seq: true,
  };
  const [, setState] = useState(searchState);
  const [currentDataSet, setCurrentDataSet] = useState(currentData);
  const [columnProperties, setColumnProperties] = useState(columnState);

  let handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  let handleReset = (clearFilters: any) => {
    clearFilters();
    setState({ searchedColumn: "", searchText: "" });
  };

  useEffect(() => {
    getRepositoryData(setLoading, setData, setCurrentDataSet);
  }, []);

  let getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: {
      setSelectedKeys: any;
      selectedKeys: any;
      confirm: any;
      clearFilters: any;
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput, 100);
      }
    },
  });
  let columns = getDesktopColumns(getColumnSearchProps, columnProperties);

  let downloadCertainColumnCsv = (currentDataSet: any) => {
    let columnArray: string[] = [];
    if (columnProperties.top_classes && columnProperties.equivalent) {
      columnArray.push("eq_class");
    }
    if (columnProperties.top_classes && columnProperties.member_count) {
      columnArray.push("members");
    }
    if (
      columnProperties.top_representative &&
      columnProperties.representative
    ) {
      columnArray.push("representative");
    }
    if (columnProperties.top_representative && columnProperties.model) {
      columnArray.push("model");
    }
    if (columnProperties.top_representative && columnProperties.chains) {
      columnArray.push("chains");
    }
    if (columnProperties.top_representative && columnProperties.molecule) {
      columnArray.push("molecule");
    }
    if (columnProperties.top_representative && columnProperties.residues) {
      columnArray.push("nts");
    }
    
    return JsonToCsvButton(currentDataSet, columnArray, "rnasolo-main-table");
  };

  return (
    <>
      <Dropdown arrow={true} overlayStyle={{userSelect: 'none'}}
        overlay={getDropdownList(columnProperties, setColumnProperties)} 
      >
        <Button style={{ marginBottom: "15px" }}>
          <TableOutlined />
          Show/Hide columns
          <DownOutlined />
        </Button>
      </Dropdown>
      {Object.values(columnProperties).every((v) => v === false) ? (
        <h1>Every columns are hidden</h1>
      ) : (
        <>
          {downloadCertainColumnCsv(currentDataSet)}
          <Table
            id={"repository-table"}
            columns={columns}
            scroll={{ x: "100%" }}
            dataSource={data}
            onChange={(
              pagination: any,
              filters: any,
              sorter: any,
              extra: any
            ) => {
              setCurrentDataSet(extra.currentDataSource);
            }}
            loading={loading}
          />
        </>
      )}
    </>
  );
}
