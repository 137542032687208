import { Button, Checkbox, Image, Menu, Tag, Tooltip } from "antd";
import configData from "../../../config.json";
import {
  column_hider,
  repository_list,
  repository_list_class_3d_file,
  repository_list_class_structure_file,
  repository_list_representative_file,
} from "../../../types";

export function getDesktopColumns(
  getColumnSearchProps: any,
  activeColumns: column_hider
) {
  return [
    {
      title: "Equivalence class",

      show:
        activeColumns.top_classes &&
        (activeColumns.equivalent || activeColumns.member_count),
      ellipsis: !(
        activeColumns.top_classes &&
        (activeColumns.equivalent || activeColumns.member_count)
      ),
      width:
        activeColumns.top_classes &&
        (activeColumns.equivalent || activeColumns.member_count)
          ? "auto"
          : "0px",

      children: [
        {
          title: "Identifier",
          dataIndex: "eq_class",
          key: "eq_class",

          show: activeColumns.top_classes && activeColumns.equivalent,
          ellipsis: !(activeColumns.top_classes && activeColumns.equivalent),
          width:
            activeColumns.top_classes && activeColumns.equivalent
              ? "auto"
              : "0px",

          ...getColumnSearchProps("eq_class"),
          render: (equivalent: string, row: repository_list) => (
            <Tooltip
              placement="topLeft"
              title={
                "Source name: " + (row.name.length === 0 ? " - " : row.name)
              }
            >
              <a
                href={
                  "http://rna.bgsu.edu/rna3dhub/nrlist/view/NR_all_" +
                  equivalent
                }
                target={"_blank"}
                rel="noreferrer"
              >
                <Tag color={"volcano"} key={equivalent}>
                  {" "}
                  {equivalent}
                </Tag>
              </a>
            </Tooltip>
          ),
        },
        {
          title: "Cardinality",
          dataIndex: "members",
          key: "members",

          show: activeColumns.top_classes && activeColumns.member_count,
          ellipsis: !(activeColumns.top_classes && activeColumns.member_count),
          width:
            activeColumns.top_classes && activeColumns.member_count
              ? "auto"
              : "0px",

          sorter: (a: any, b: any) => a.members - b.members,
        },
      ],
    },
    {
      title: "Equivalence class representative",
      show:
        activeColumns.top_representative &&
        (activeColumns.representative ||
          activeColumns.model ||
          activeColumns.chains ||
          activeColumns.molecule ||
          activeColumns.rendered_structure),
      ellipsis: !(
        activeColumns.top_representative &&
        (activeColumns.representative ||
          activeColumns.model ||
          activeColumns.chains ||
          activeColumns.molecule ||
          activeColumns.rendered_structure)
      ),
      width:
        activeColumns.top_representative &&
        (activeColumns.representative ||
          activeColumns.model ||
          activeColumns.chains ||
          activeColumns.molecule ||
          activeColumns.rendered_structure)
          ? "auto"
          : "0px",
      children: [
        {
          title: "PDB ID",
          dataIndex: "representative",
          key: "representative",

          show:
            activeColumns.representative && activeColumns.top_representative,
          ellipsis: !(
            activeColumns.top_representative && activeColumns.representative
          ),
          width:
            activeColumns.top_representative && activeColumns.representative
              ? "auto"
              : "3px",

          render: (text: any, row: repository_list) => (
            <Tooltip
              placement="topLeft"
              title={
                "Structure title: " +
                (row.title.length === 0 ? " - " : row.title)
              }
            >
              <Tag color={"#325880"}>
                <a
                  href={"https://www.rcsb.org/structure/" + text}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {text}
                </a>
              </Tag>
            </Tooltip>
          ),
          ...getColumnSearchProps("representative"),
        },
        {
          title: "Model no.",
          dataIndex: "model",
          key: "model",

          show: activeColumns.top_representative && activeColumns.model,
          ellipsis: !(activeColumns.top_representative && activeColumns.model),
          width:
            activeColumns.top_representative && activeColumns.model
              ? "auto"
              : "0px",

          sorter: {
            compare: (a: any, b: any) => a.model - b.model,
            multiple: 1,
          },
        },
        {
          title: "Chain(s)",
          dataIndex: "chains",
          key: "chains",

          show: activeColumns.top_representative && activeColumns.chains,
          ellipsis: !(activeColumns.top_representative && activeColumns.chains),
          width:
            activeColumns.top_representative && activeColumns.chains
              ? "auto"
              : "0px",

          sorter: {
            compare: (a: any, b: any) => a.chains.localeCompare(b.chains),
            multiple: 1,
          },
        },
        {
          title: "Molecule",
          dataIndex: "molecule",
          key: "moleucle",

          show: activeColumns.top_representative && activeColumns.molecule,
          ellipsis: !(
            activeColumns.top_representative && activeColumns.molecule
          ),
          width:
            activeColumns.top_representative && activeColumns.molecule
              ? "auto"
              : "0px",

          filters: [
            {
              text: "Protein-RNA Complex",
              value: "CR",
            },
            {
              text: "DNA-RNA Hybrid",
              value: "HDR",
            },
            {
              text: "Solo RNA",
              value: "A",
            },
          ],
          onFilter: (value: any, record: any) =>
            record.molecule.indexOf(value) === 0,

          render: (text: any) => {
            switch (text) {
              case "CR":
                return "Protein-RNA Complex";
              case "HDR":
                return "DNA-RNA Hybrid";
              case "A":
                return "Solo RNA";
              default:
                return "No data";
            }
          },
        },
        {
          title: "#nts",
          dataIndex: "nts",
          key: "nts",

          show: activeColumns.top_representative && activeColumns.residues,
          ellipsis: !(
            activeColumns.top_representative && activeColumns.residues
          ),
          width:
            activeColumns.top_representative && activeColumns.residues
              ? "auto"
              : "0px",

          sorter: {
            compare: (a: any, b: any) => a.nts - b.nts,
            multiple: 1,
          },
          render: (nts: number) => {
            if (nts === 0) {
              return "n/a";
            } else {
              return nts;
            }
          },
        },
        {
          title: "3D structure preview",
          key: "rendered_structure",

          show:
            activeColumns.top_representative &&
            activeColumns.rendered_structure,
          ellipsis: !(
            activeColumns.top_representative && activeColumns.rendered_structure
          ),
          width:
            activeColumns.top_representative && activeColumns.rendered_structure
              ? "200px"
              : "0px",

          dataIndex: "rendered_structure",
          render: (rendered_structure: any) => {
            let file_array = rendered_structure.split("/");
            return rendered_structure.length > 0 ? (
              <>
                <a
                  href={
                    "/molstar/" +
                    file_array[file_array.length - 1].split(".")[0] +
                    "/"
                  }
                  rel="noreferrer"
                  target="_blank"
                >
                  <Tooltip
                    placement="top"
                    title={"Click to open in mol* viewer"}
                  >
                    <Image
                      preview={false}
                      src={
                        configData.SERVER_URL + `media/${rendered_structure}`
                      }
                    >
                      {" "}
                    </Image>
                  </Tooltip>
                </a>
              </>
            ) : (
              <div></div>
            );
          },
        },
      ],
    },
    {
      title: "Download sequence(s)",
      show:
        activeColumns.top_download_sequence &&
        (activeColumns.class_file_seq || activeColumns.representative_file_seq),
      ellipsis: !(
        activeColumns.top_download_sequence &&
        (activeColumns.class_file_seq || activeColumns.representative_file_seq)
      ),
      width:
        activeColumns.top_download_sequence &&
        (activeColumns.class_file_seq || activeColumns.representative_file_seq)
          ? "auto"
          : "0px",
      children: [
        {
          title: "All class members",
          key: "class_file_structure",
          dataIndex: "class_file_structure",

          show:
            activeColumns.top_download_sequence && activeColumns.class_file_seq,
          ellipsis: !(
            activeColumns.top_download_sequence && activeColumns.class_file_seq
          ),
          width:
            activeColumns.top_download_sequence && activeColumns.class_file_seq
              ? "auto"
              : "3px",

          render: (
            files: repository_list_class_structure_file,
            row: repository_list
          ) => {
            return (
              <div style={{ textAlign: "center" }}>
                {files.all_members ? (
                  <>
                    <a
                      href={
                        configData.SERVER_URL +
                        "files/member/archive/fasta/" +
                        row.eq_class +
                        "/"
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Button
                        type="primary"
                        style={{ margin: "10px", minWidth: "60px" }}
                        ghost
                      >
                        FASTA
                      </Button>
                    </a>
                    <br />
                  </>
                ) : (
                  <>None</>
                )}
              </div>
            );
          },
        },
        {
          title: "Representative",
          key: "class_file_structure",
          dataIndex: "class_file_structure",

          show:
            activeColumns.top_download_sequence &&
            activeColumns.representative_file_seq,
          ellipsis: !(
            activeColumns.top_download_sequence &&
            activeColumns.representative_file_seq
          ),
          width:
            activeColumns.top_download_sequence &&
            activeColumns.representative_file_seq
              ? "auto"
              : "3px",

          render: (files: repository_list_class_structure_file) => {
            return (
              <div style={{ textAlign: "center" }}>
                {files.representative.length > 0 ? (
                  <>
                    <a
                      href={
                        configData.SERVER_URL + `media/${files.representative}`
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Button
                        type="primary"
                        style={{ margin: "10px", minWidth: "60px" }}
                        ghost
                      >
                        FASTA
                      </Button>
                    </a>
                    <br />
                  </>
                ) : (
                  <>None</>
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: "Download 3D structure(s)",
      show:
        activeColumns.top_download &&
        (activeColumns.class_file_3d || activeColumns.representative_file_3d),
      ellipsis: !(
        activeColumns.top_download &&
        (activeColumns.class_file_3d || activeColumns.representative_file_3d)
      ),
      width:
        activeColumns.top_download &&
        (activeColumns.class_file_3d || activeColumns.representative_file_3d)
          ? "auto"
          : "0px",
      children: [
        {
          title: "All class members",
          key: "class_file_3d",
          dataIndex: "class_file_3d",

          show: activeColumns.top_download && activeColumns.class_file_3d,
          ellipsis: !(
            activeColumns.top_download && activeColumns.class_file_3d
          ),
          width:
            activeColumns.top_download && activeColumns.class_file_3d
              ? "auto"
              : "3px",

          render: (
            files: repository_list_class_3d_file,
            row: repository_list
          ) => {
            let cif_file = files.cif_file;
            let pdb_file = files.pdb_file;
            return (
              <div style={{ textAlign: "center" }}>
                {cif_file ? (
                  <>
                    <a
                      href={
                        configData.SERVER_URL +
                        "files/member/archive/cif/" +
                        row.eq_class +
                        "/"
                      }
                      rel="noreferrer"
                      target="_blank"
                    >
                      <Button
                        type="primary"
                        style={{ margin: "10px", minWidth: "60px" }}
                        ghost
                      >
                        CIF
                      </Button>
                    </a>
                    <br />
                  </>
                ) : (
                  <></>
                )}

                {pdb_file ? (
                  <a
                    href={
                      configData.SERVER_URL +
                      "files/member/archive/pdb/" +
                      row.eq_class +
                      "/"
                    }
                    rel="noreferrer"
                    target="_blank"
                  >
                    <Button
                      type="primary"
                      style={{ margin: "10px", minWidth: "60px" }}
                      ghost
                    >
                      PDB
                    </Button>
                  </a>
                ) : (
                  <></>
                )}
              </div>
            );
          },
        },
        {
          title: "Representative",
          key: "representative_file",
          dataIndex: "representative_file",

          show:
            activeColumns.top_download && activeColumns.representative_file_3d,
          ellipsis: !(
            activeColumns.top_download && activeColumns.representative_file_3d
          ),
          width:
            activeColumns.top_download && activeColumns.representative_file_3d
              ? "auto"
              : "0px",

          render: (files: repository_list_representative_file) => {
            let cif_file = files.cif_file;
            let pdb_file = files.pdb_file;
            return pdb_file.length > 0 ? (
              <div style={{ textAlign: "center" }}>
                <a
                  href={configData.SERVER_URL + `media/${cif_file}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button
                    type="primary"
                    style={{ margin: "10px", minWidth: "60px" }}
                    ghost
                  >
                    CIF
                  </Button>
                </a>
                <br />
                <a
                  href={configData.SERVER_URL + `media/${pdb_file}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button
                    type="primary"
                    style={{ margin: "10px", minWidth: "60px" }}
                    ghost
                  >
                    PDB
                  </Button>
                </a>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                <a
                  href={configData.SERVER_URL + `media/${cif_file}`}
                  rel="noreferrer"
                  target="_blank"
                >
                  <Button
                    type="primary"
                    style={{ margin: "10px", minWidth: "60px" }}
                    ghost
                  >
                    CIF
                  </Button>
                </a>
              </div>
            );
          },
        },
      ],
    },
  ];
}

export function getDropdownList(
  columnProperties: column_hider,
  setColumnProperties: any
) {
  return (
    <Menu>
      <Menu.Item>
        <Checkbox
          checked={columnProperties.top_classes}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              top_classes: !columnProperties.top_classes,
              equivalent: !columnProperties.top_classes,
              member_count: !columnProperties.top_classes,
            }));
          }}
        >
          {"Equivalence class"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.equivalent}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              equivalent: !columnProperties.equivalent,
            }));
            if (!columnProperties.equivalent) {
              setColumnProperties((current: any) => ({
                ...current,
                top_classes: true,
              }));
            }
            if (!columnProperties.member_count && columnProperties.equivalent) {
              setColumnProperties((current: any) => ({
                ...current,
                top_classes: false,
              }));
            }
          }}
        >
          {"Class Identifier"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.member_count}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              member_count: !columnProperties.member_count,
            }));
            if (!columnProperties.member_count) {
              setColumnProperties((current: any) => ({
                ...current,
                top_classes: true,
              }));
            }
            if (columnProperties.member_count && !columnProperties.equivalent) {
              setColumnProperties((current: any) => ({
                ...current,
                top_classes: false,
              }));
            }
          }}
        >
          {"Class cardinality"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          checked={columnProperties.top_representative}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              top_representative: !columnProperties.top_representative,
              representative: !columnProperties.top_representative,
              model: !columnProperties.top_representative,
              chains: !columnProperties.top_representative,
              molecule: !columnProperties.top_representative,
              residues: !columnProperties.top_representative,
              rendered_structure: !columnProperties.top_representative,
            }));
          }}
        >
          {"Equivalence class representative"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.representative}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              representative: !columnProperties.representative,
            }));
            if (!columnProperties.representative) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: true,
              }));
            }
            if (
              columnProperties.representative &&
              !columnProperties.model &&
              !columnProperties.chains &&
              !columnProperties.molecule &&
              !columnProperties.residues &&
              !columnProperties.rendered_structure
            ) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: false,
              }));
            }
          }}
        >
          {"PDB ID"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.model}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              model: !columnProperties.model,
            }));
            if (!columnProperties.model) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: true,
              }));
            }
            if (
              !columnProperties.representative &&
              columnProperties.model &&
              !columnProperties.chains &&
              !columnProperties.molecule &&
              !columnProperties.residues &&
              !columnProperties.rendered_structure
            ) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: false,
              }));
            }
          }}
        >
          {"Model no."}
        </Checkbox>
      </Menu.Item>

      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.chains}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              chains: !columnProperties.chains,
            }));
            if (!columnProperties.chains) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: true,
              }));
            }
            if (
              !columnProperties.representative &&
              !columnProperties.model &&
              columnProperties.chains &&
              !columnProperties.molecule &&
              !columnProperties.residues &&
              !columnProperties.rendered_structure
            ) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: false,
              }));
            }
          }}
        >
          {"Chain(s)"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.molecule}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              molecule: !columnProperties.molecule,
            }));
            if (!columnProperties.molecule) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: true,
              }));
            }
            if (
              !columnProperties.representative &&
              !columnProperties.model &&
              !columnProperties.chains &&
              columnProperties.molecule &&
              !columnProperties.residues &&
              !columnProperties.rendered_structure
            ) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: false,
              }));
            }
          }}
        >
          {"Molecule"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.residues}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              residues: !columnProperties.residues,
            }));
            if (!columnProperties.residues) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: true,
              }));
            }
            if (
              !columnProperties.representative &&
              !columnProperties.model &&
              !columnProperties.chains &&
              !columnProperties.molecule &&
              columnProperties.residues &&
              !columnProperties.rendered_structure
            ) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: false,
              }));
            }
          }}
        >
          {"#nts"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.rendered_structure}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              rendered_structure: !columnProperties.rendered_structure,
            }));
            if (!columnProperties.rendered_structure) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: true,
              }));
            }
            if (
              !columnProperties.representative &&
              !columnProperties.model &&
              !columnProperties.chains &&
              !columnProperties.molecule &&
              !columnProperties.residues &&
              columnProperties.rendered_structure
            ) {
              setColumnProperties((current: any) => ({
                ...current,
                top_representative: false,
              }));
            }
          }}
        >
          {"3D structure preview"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          checked={columnProperties.top_download_sequence}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              top_download_sequence: !columnProperties.top_download_sequence,
              class_file_seq: !columnProperties.top_download_sequence,
              representative_file_seq: !columnProperties.top_download_sequence,
            }));
          }}
        >
          {"Download sequence(s)"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.class_file_seq}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              class_file_seq: !columnProperties.class_file_seq,
            }));
            if (!columnProperties.class_file_seq) {
              setColumnProperties((current: any) => ({
                ...current,
                top_download_sequence: true,
              }));
            }
          }}
        >
          {"Download class members"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.representative_file_seq}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              representative_file_seq: !columnProperties.representative_file_seq,
            }));
            if (!columnProperties.representative_file_seq) {
              setColumnProperties((current: any) => ({
                ...current,
                top_download_sequence: true,
              }));
            }
          }}
        >
          {"Download representative"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          checked={columnProperties.top_download}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              top_download: !columnProperties.top_download,
              class_file_3d: !columnProperties.top_download,
              representative_file_3d: !columnProperties.top_download,
            }));
          }}
        >
          {"Download 3D structure(s)"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.class_file_3d}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              class_file_3d: !columnProperties.class_file_3d,
            }));
            if (!columnProperties.class_file_3d) {
              setColumnProperties((current: any) => ({
                ...current,
                top_download: true,
              }));
            }
            if (
              columnProperties.representative_file_3d &&
              columnProperties.class_file_3d
            ) {
              setColumnProperties((current: any) => ({
                ...current,
                top_download: false,
              }));
            }
          }}
        >
          {"Download class members"}
        </Checkbox>
      </Menu.Item>
      <Menu.Item>
        <Checkbox
          className={"sub-item"}
          checked={columnProperties.representative_file_3d}
          onChange={() => {
            setColumnProperties((current: any) => ({
              ...current,
              representative_file_3d: !columnProperties.representative_file_3d,
            }));
            if (!columnProperties.representative_file_3d) {
              setColumnProperties((current: any) => ({
                ...current,
                top_download: true,
              }));
            }
            if (
              columnProperties.representative_file_3d &&
              columnProperties.class_file_3d
            ) {
              setColumnProperties((current: any) => ({
                ...current,
                top_download: false,
              }));
            }
          }}
        >
          {"Download representative"}
        </Checkbox>
      </Menu.Item>
    </Menu>
  );
}
