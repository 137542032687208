import { Button, Form, Input } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import CSRFTOKEN from "../CRSFTOKEN";

export function LoginPane(onFinish: any) {
  return (
    <div>
      <h1 style={{ textAlign: "center", padding: "20px", fontSize: "30px" }}>
        Admin Dashboard
      </h1>
      <Form
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
        style={{ width: "500px", marginLeft: "auto", marginRight: "auto" }}
      >
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your Username!" }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your Password!" }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Password"
          />
        </Form.Item>
        <CSRFTOKEN />
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
