import configData from "../../config.json";

function splitIntoChunk(arr: any, chunk: any) {
  let newArray = [];
  for (let i = 0; i < arr.length; i += chunk) {
    newArray.push(arr.slice(i, i + chunk));
  }

  return newArray;
}

export function getRepositoryData(
  setLoading: any,
  setData: any,
  setCurrentDataSet: any
) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(configData.SERVER_URL + "api/list/", requestOptions)
    .then((response) => response.json())
    .then((response) => {
      setLoading(true);
      setData(response.results);
      setCurrentDataSet(response.results);
      setLoading(false);
    })
    .catch((error) => console.log(error.message));
}

export function getRepositoryPartitionData(
  setLoading: any,
  setPartition: any,
  setListedItems: any,
  setFullResult: any
) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(configData.SERVER_URL + "api/list/", requestOptions)
    .then((response) => response.json())
    .then((response) => {
      setListedItems({});
      setLoading(true);
      // setData({
      //     data: response.results
      // });
      setFullResult(response.results);
      setPartition(splitIntoChunk(response.results, 30));
      setListedItems(response.results.slice(0, 30));
      setLoading(false);
    })
    .catch((error) => console.log(error.message));
}
