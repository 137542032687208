import configData from "../../config.json";

export function getUpdateLogData(setLoading: any, setData: any) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  };
  fetch(configData.SERVER_URL + "api/get/updates/info/", requestOptions)
    .then((response) => response.json())
    .then((response) => {
      setData({
        all: response.results,
      });
      setLoading(false);
    })
    .catch((error) => console.log(error.message));
}
