import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";
import { CustomYAxis } from "../../../styles/Statistics/CustomYAxis";
import { EqChartTooltipWithX } from "../../../styles/Statistics/EqChartTooltip";

export function EqCardinalityChart(data: any, isDesktop: any) {
  return (
    <ResponsiveContainer height="100%" minHeight={350}>
      <ComposedChart
        width={800}
        height={500}
        data={data.result}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis height={70} dataKey="cardinality">
          <Label
            value={"Class cardinality"}
            position="outside"
            fill="#676767"
            style={{ fontWeight: 700 }}
            fontSize={16}
          />
        </XAxis>
        {isDesktop ? (
          <YAxis
            width={140}
            yAxisId="left"
            orientation={"left"}
            tick={<CustomYAxis color={"#676767"} orientation={"left"} />}
          >
            <Label
              value={"Classes with certain cardinality"}
              angle={-90}
              position="center"
              fill="#676767"
              style={{ fontWeight: 700 }}
              fontSize={16}
            />
          </YAxis>
        ) : (
          ""
        )}
        {isDesktop ? (
          <YAxis
            width={140}
            yAxisId="right"
            tick={<CustomYAxis color={"#676767"} />}
            orientation="right"
          />
        ) : (
          ""
        )}
        <Tooltip
          content={<EqChartTooltipWithX />}
          formatter={(value: any) => new Intl.NumberFormat("en").format(value)}
        />
        <Bar
          yAxisId="left"
          dataKey="count"
          name={"Classes with certain cardinality"}
          fill={"#E2475D"}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
