import "./assets/css/App.css";
import "./containers/Home/Home";

import { useMediaQuery } from "react-responsive";

import { MobileNav } from "./components/appComponent/MobileNav";
import { DesktopNav } from "./components/appComponent/DesktopNav";

export default function App() {
  let isDesktop = useMediaQuery({ query: "(min-width: 900px)" });
  return <div>{isDesktop ? <DesktopNav /> : <MobileNav />}</div>;
}
