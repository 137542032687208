import {  Alert, Layout } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import mainLogo from "../../../assets/logo.png";
import { UpdateBanner } from "../../../containers/UpdateBanner";

import { AppRouter } from "../../../router";
import { DesktopNavHeader } from "./nav";

export const DesktopNav = () => {
  return (
    <div className="App">
      <Layout>
        <Header className="header">
          <a href={"/"}>
            <div className="logo" style={{ float: "left" }}>
              <img style={{ height: "50px" }} src={mainLogo} alt={"nothing"} />
            </div>
            <div
              className="logo"
              style={{
                float: "left",
                fontFamily: "Ubuntu",
                fontSize: "25px",
                color: "white",
              }}
            >
              &nbsp;RNAsolo: repository of cleaned PDB-derived RNA 3D structures
            </div>
          </a>
        </Header>
        <a href="https://mirna.cs.put.poznan.pl">
          <Alert
            message="Click here to check new beta version of RNAsolo2.0"
            banner
            type="info"
            style={{ textAlign: "center" }}
          />
        </a>
        <UpdateBanner/>
        <Content style={{ padding: "0 50px" }}>
          <Layout
            className="site-layout-background"
            style={{ padding: "24px 0" }}
          >
            <DesktopNavHeader />
            <AppRouter />
          </Layout>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          RNAsolo {new Date().getFullYear()} |{" "}
          <a href={"https://github.com/adamczykb"} style={{ color: "black" }}>
            Bartosz Adamczyk
          </a>
        </Footer>
      </Layout>
    </div>
  );
};
